import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../utils";
import { CiteButton } from "../../apps/CiteButton";
import { AppDescriptionButton } from "./AppDescriptionButton";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { AppExamplesButton } from "./AppExamplesButton";
import { AppDocs } from "../../apps/AppDocs";
import { AppExamplesPanel } from "../../apps/AppExamplesPanel";
export function AppInfo({ appManifest, setFormValues, }) {
    const [showExamples, setShowExamples] = useState(false);
    const [showDocs, setShowDocs] = useState(false);
    const [currentLocation, setLocation] = useLocation();
    // Open docs panel when the page is loaded if the url includes docs=true
    useEffect(() => {
        const queryParamsOrig = new URLSearchParams(window.location.search);
        setShowDocs(!!queryParamsOrig.get("docs"));
    });
    const setOpenAppDocs = (activate) => {
        const queryParams = new URLSearchParams(window.location.search);
        if (activate) {
            queryParams.set("docs", "true");
            setShowDocs(true);
            setShowExamples(false);
        }
        else {
            queryParams.delete("docs");
            setShowDocs(false);
        }
        const urlParamsStr = queryParams.toString();
        const newUrl = `${currentLocation}${urlParamsStr ? `?${urlParamsStr}` : ""}`;
        setLocation(newUrl);
    };
    const toggleOpenAppDocs = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const appDocs = queryParams.get("docs");
        if ((appDocs === null || appDocs === void 0 ? void 0 : appDocs.toLowerCase()) === "true") {
            setOpenAppDocs(false);
        }
        else {
            setOpenAppDocs(true);
        }
    };
    const toggleShowExamples = () => {
        if (showExamples) {
            setShowExamples(false);
        }
        else {
            setShowExamples(true);
            setOpenAppDocs(false);
        }
    };
    return (_jsxs(Box, Object.assign({ sx: { pb: 3 } }, { children: [_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { pt: 1 } }, { children: capitalizeFirstLetter(appManifest.name) })), _jsx(Typography, Object.assign({ variant: "body1", sx: { pb: 1 } }, { children: appManifest.description })), _jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    gap: 2,
                } }, { children: [_jsx(AppExamplesButton, { toggleShowExamples: toggleShowExamples, showExamples: showExamples }), _jsx(AppDescriptionButton, { appDocsIsOpen: showDocs, toggleOpenAppDocs: toggleOpenAppDocs }), !!appManifest.citations && (_jsx(CiteButton, { appName: appManifest.name, citations: appManifest.citations }))] })), _jsx(Divider, { sx: { my: 1 } }), _jsx(AppDocs, { appName: appManifest.name, appDocsIsOpen: showDocs, closeAppDocs: () => setOpenAppDocs(false) }), _jsx(AppExamplesPanel, { appName: appManifest.name, appVersion: appManifest.api_version, showExamples: showExamples, closeExamples: () => setShowExamples(false), setFormValues: setFormValues })] })));
}
