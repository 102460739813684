import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Accordion, AccordionDetails, AccordionSummary, Grid, } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
function AccordionName({ hasErrors, name, }) {
    return hasErrors ? (_jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: name })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ErrorOutlineIcon, { color: "error", sx: { fontSize: "1.2em" } }) }))] }))) : (_jsx(_Fragment, { children: name }));
}
export function ControlledAccordion({ children, open, callback, name, variant, errors, hoverBackground, }) {
    const handleChange = (event) => {
        if (callback)
            callback();
    };
    return (_jsxs(Accordion, Object.assign({ expanded: open, onChange: handleChange, sx: {
            width: "100%",
            marginTop: 0,
            maxWidth: "100%",
            "&:before": {
                display: "none",
            },
            "& .MuiAccordionDetails-root": { pt: 0, pr: 0, pb: 0, pl: 2 },
            "& .MuiAccordionSummary-root": { minHeight: 0 },
        }, disableGutters: true, elevation: 0, variant: variant }, { children: [_jsx(AccordionSummary, Object.assign({ expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1a-content", id: "panel1a-header", sx: {
                    ":hover": {
                        backgroundColor: hoverBackground
                            ? "rgba(0, 0, 0, 0.04)"
                            : undefined,
                    },
                } }, { children: _jsx(AccordionName, { name: name, hasErrors: errors }) })), _jsx(AccordionDetails, Object.assign({ sx: { mb: 2 } }, { children: children }))] })));
}
