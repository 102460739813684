import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Popover from "@mui/material/Popover";
import { Paper, Button } from "@mui/material";
export default function ColorPickerPopover({ color, onColorChange, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (_jsxs("div", { children: [_jsx(Paper, Object.assign({ sx: { height: "28px", width: "28px" } }, { children: _jsx(Button, { "aria-describedby": id, onClick: handleClick, sx: {
                        backgroundColor: color,
                        height: "100%",
                        width: "100%",
                        minWidth: 0,
                        "&:hover": {
                            backgroundColor: color,
                            filter: "brightness(85%)",
                        },
                    } }) })), _jsxs(Popover, Object.assign({ id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                }, sx: {
                    "& .MuiPaper-root": { borderRadius: "9px", p: 1 },
                    "& .react-colorful__pointer": {
                        height: "20px",
                        width: "20px",
                    },
                    "& .react-colorful": {
                        height: "180px",
                        width: "200px",
                    },
                    "& .react-colorful__hue": {
                        height: "20px",
                    },
                    "& input": {
                        // border: "none",
                        border: "1px solid #e6e6e6",
                        // width: "100px",
                        mt: 1,
                        p: "5px",
                        "input:focus": {
                            border: "none",
                        },
                    },
                } }, { children: [_jsx(HexColorPicker, { color: color, onChange: onColorChange }), _jsx(HexColorInput, { color: color, onChange: onColorChange, prefixed: true })] }))] }));
}
// const checkHexColor = (hexPre: string) => {
//   var reg = /^#([0-9a-f]{3}){1,2}$/i;
//   return reg.test(hexPre) ? hexPre : "#ffffff";
// };
/*
  TODO: this is called inexplicably many times,
  the consuming components could use a little optimization.
*/
function numToHex(colornum) {
    if (colornum === undefined) {
        console.log("Color number input is undefined, falling back to #ffffff.");
        return "#ffffff";
    }
    return "#" + ("000000" + colornum.toString(16)).slice(-6);
}
function hexToNum(color) {
    return Number("0x" + color.replace("#", ""));
}
export function SelectColor({ activeColor, callback, }) {
    const [color, setColor] = useState(numToHex(activeColor));
    useEffect(() => {
        setColor(numToHex(activeColor));
    }, [activeColor]);
    useEffect(() => {
        const colorNum = hexToNum(color);
        if (colorNum !== activeColor) {
            callback(colorNum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color]);
    return _jsx(ColorPickerPopover, { color: color, onColorChange: setColor });
}
