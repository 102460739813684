import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, Select } from "@mui/material";
import { DataVizTabs } from "../../DataViz/DataVizTabs";
function SelectPlotSelector({ handleChange, activePlotIdx, plots, }) {
    const onChange = (event) => {
        handleChange(Number(event.target.value));
    };
    return (_jsx(Select, Object.assign({ labelId: "select-table-label", id: "select-table", value: activePlotIdx.toString(), label: "", onChange: onChange }, { children: plots.map((t, i) => (_jsx(MenuItem, Object.assign({ value: i, sx: { display: t.archived ? "none" : undefined } }, { children: t.name }), `opt${i}`))) })));
}
function SelectPloTabs({ handleChange, activePlotIdx, plots, }) {
    const plotNames = plots.map((p) => {
        return { name: p.name };
    });
    const callback = (val) => {
        handleChange(plotNames.findIndex((p) => p.name === val));
    };
    return (_jsx(DataVizTabs, { tabs: plotNames, activeTab: plots[activePlotIdx].name, callback: callback }));
}
export function SelectPlot({ handleChange, activePlotIdx, plots, fileSelectorAsTabs, }) {
    return fileSelectorAsTabs ? (_jsx(SelectPloTabs, { handleChange: handleChange, activePlotIdx: activePlotIdx, plots: plots })) : (_jsx(SelectPlotSelector, { handleChange: handleChange, activePlotIdx: activePlotIdx, plots: plots }));
}
