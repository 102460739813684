// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var TableClickAction;
(function (TableClickAction) {
    TableClickAction["loadFile"] = "loadFile";
    TableClickAction["toggleVisibility"] = "toggleVisibility";
    TableClickAction["focusCoords"] = "focusCoords";
})(TableClickAction || (TableClickAction = {}));
export var TableRowButtonAction;
(function (TableRowButtonAction) {
    TableRowButtonAction["displayImage"] = "displayImage";
})(TableRowButtonAction || (TableRowButtonAction = {}));
