import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, CircularProgress, Grid, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import { jobListStore } from "../../Sidebar";
import { shallow } from "zustand/shallow";
import { capitalizeFirstLetter } from "../../FormGenerator/utils";
import { statusToColor } from "../../Sidebar/JobStatusSharedState";
import { useInterval } from "../../utils";
import { userAuthStore } from "../../Authentication/userAuthState";
function StatusTable({ queueJobs }) {
    const [userInfo] = userAuthStore((state) => [state.userInfo], shallow);
    const userName = userInfo ? capitalizeFirstLetter(userInfo.username) : "User";
    const cellStyle = { fontSize: 12 };
    const headerCellStyle = Object.assign({ fontWeight: "bold" }, cellStyle);
    const userIdToName = (userId) => {
        if (userId === "pmgui-default")
            return "Public account";
        if (userId === "registered-user")
            return "Registered User";
        if (userId === (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userId))
            return userName;
        return userId;
    };
    return (_jsx(TableContainer, Object.assign({ component: Paper }, { children: _jsxs(Table, Object.assign({ size: "small", sx: { overflowX: "visible" } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Job ID" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Parent ID" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "User" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Application" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Status" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Progress" })), _jsx(TableCell, Object.assign({ sx: headerCellStyle }, { children: "Date" }))] }) }), _jsx(TableBody, { children: queueJobs.map((job) => (_jsxs(TableRow, { children: [_jsx(TableCell, Object.assign({ sx: cellStyle }, { children: job.traceId })), _jsx(TableCell, Object.assign({ sx: cellStyle }, { children: job.parentId })), _jsx(TableCell, Object.assign({ sx: cellStyle }, { children: userIdToName(job.userID) })), _jsx(TableCell, Object.assign({ sx: cellStyle }, { children: job.container })), _jsx(TableCell, Object.assign({ sx: Object.assign({ color: job.status in statusToColor
                                        ? statusToColor[job.status]
                                        : undefined }, cellStyle) }, { children: job.status })), _jsx(TableCell, { children: job.progress }), _jsx(TableCell, { children: job.formattedDate })] }, `queue-status-table-${job.traceId}`))) })] })) })));
}
function StatusDetails({ queueJobs }) {
    return (_jsx(Box, { children: queueJobs.length === 0 ? (_jsx(Box, Object.assign({ sx: { p: 4 } }, { children: "The queue is empty." }))) : (_jsx(StatusTable, { queueJobs: queueJobs })) }));
}
export function QueueStatus() {
    const statusToColor = {
        running: "#06d6a0",
        busy: "#fb8500",
        congested: "#ef476f",
    };
    const [queueJobs, queueStatus] = jobListStore((state) => [state.queueJobs, state.queueStatus], shallow);
    const statusColor = queueStatus && queueStatus in statusToColor
        ? statusToColor[queueStatus]
        : "#cccccc";
    const updateQueueStatus = () => {
        window.highPriorityMsg({
            type: "fetch-queue-status",
            url: window.location.origin,
        });
    };
    //Get job status when component renders for the 1st time
    useEffect(() => {
        updateQueueStatus();
    }, []);
    //Get if the status was not obtained because the worker was busy, try again
    const [statusInterval, setStatusInterval] = useState(1000);
    useInterval(() => {
        if (queueStatus !== undefined) {
            setStatusInterval(null);
            return;
        }
        updateQueueStatus();
    }, statusInterval);
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = (event) => {
        //@ts-ignore
        setAnchorEl(event.currentTarget);
    };
    const closePopover = () => {
        setAnchorEl(null);
    };
    const open = !!anchorEl;
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ onClick: (e) => {
                    updateQueueStatus();
                    openPopover(e);
                }, container: true, direction: "row", justifyContent: "flex-start", alignItems: "center", sx: {
                    cursor: "pointer",
                    mt: 1,
                    py: 1,
                    px: 2,
                    ":hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                }, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CircleIcon, { fontSize: "small", sx: { color: statusColor } }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsxs(Typography, Object.assign({ gutterBottom: true }, { children: ["Queue Status:", " ", !!queueStatus ? (_jsx("b", { children: queueStatus })) : (_jsx(CircularProgress, { sx: { mt: "5px", ml: "5px" }, size: 15 }))] })) }))] })), _jsx(Popover, Object.assign({ open: open, anchorEl: anchorEl, onClose: closePopover, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                } }, { children: _jsx(StatusDetails, { queueJobs: queueJobs }) }))] }));
}
