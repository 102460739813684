import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
export function PlayPauseButton({ isPlaying, visibility, onClick, }) {
    return (_jsx(IconButton, Object.assign({ onClick: onClick, disabled: !visibility, sx: { mb: 1 } }, { children: isPlaying ? _jsx(PauseIcon, {}) : _jsx(PlayArrowIcon, {}) })));
}
