import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { DeleteButton } from "../..";
import { Grid } from "@mui/material";
import { NAPPlotStore, PlotType, } from "../../StateStores/plots.store";
import { Panels, } from "../../GenericViewerState/SharedState";
import { InteractivePlot } from "./InteractivePlot";
import { ImagePlot } from "./ImagePlot";
import { DatavizControlOptions } from "../../DataViz";
import { InteractivePlotButtons } from "./InteractivePlotButtons";
import { SelectPlot } from "./SelectPlot";
function Plot({ onDelete, name, type, contents, vss, molstar, pyodide, options, localBasePath, NAPGenericStore, skipButtons, }) {
    const extension = name.split(".").pop();
    const skipDelete = skipButtons
        ? skipButtons.includes(DatavizControlOptions.delete)
        : false;
    return (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { my: 1, display: skipDelete ? "none" : "block" } }, { children: _jsx(DeleteButton, { onClick: onDelete, tooltipMsg: "Delete plot", placement: "top", confirmationMsg: `Are you sure you want to delete this plot?` }) })), type === PlotType.image ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(ImagePlot, { extension: extension ? extension : "png", content: contents }) }))) : (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(InteractivePlotButtons, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sx: { order: 2 } }, { children: _jsx(InteractivePlot, { content: contents, vss: vss, molstar: molstar, pyodide: pyodide, plotActions: options, localBasePath: localBasePath, NAPGenericStore: NAPGenericStore }) }))] }))] }));
}
export function Plots({ NAPGenericStore, vss, molstar, pyodide, skipButtons, fileSelectorAsTabs, }) {
    const { plots, del_plot, activePlotIdx, set_activePlotIdx } = NAPPlotStore();
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    useEffect(() => {
        const _cb = (e) => {
            setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
        };
        window.addEventListener("PM-Loaded-Table", _cb);
        return () => {
            window.removeEventListener("PM-Loaded-Table", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const closePanel = () => setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    const onDelete = () => {
        del_plot(activePlotIdx, closePanel);
    };
    const activePlot = plots[activePlotIdx];
    const numPlots = plots.filter((p) => !p.archived).length;
    return numPlots > 0 ? (_jsxs(_Fragment, { children: [numPlots > 1 && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(SelectPlot, { handleChange: set_activePlotIdx, activePlotIdx: activePlotIdx, plots: plots, fileSelectorAsTabs: fileSelectorAsTabs }) }))), _jsx(Plot, { onDelete: onDelete, contents: activePlot.contents, name: activePlot.name, type: activePlot.type, vss: vss, molstar: molstar, pyodide: pyodide, options: activePlot.options, localBasePath: activePlot.localBasePath, NAPGenericStore: NAPGenericStore, skipButtons: skipButtons })] })) : null;
}
