var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { dispatchConfirmationDialogEvent } from "../../../utils/EventDispatchers";
const DeleteButton = ({ onClick, tooltipMsg, confirmationMsg, placement, active, disabled, }) => {
    const _isActive = active === undefined ? true : active;
    const confirmDeletion = () => __awaiter(void 0, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: confirmationMsg || "",
        });
        if (ok)
            onClick();
    });
    const _backgroundColor = "auto";
    const _backgroundColorFocus = "rgb(172, 0, 0)";
    const _iconColor = "auto";
    const _iconColorFocus = "white";
    const button = (_jsx(Box, Object.assign({ onClick: disabled ? undefined : confirmationMsg ? confirmDeletion : onClick, sx: {
            cursor: "pointer",
            borderRadius: 1,
            backgroundColor: active ? _backgroundColorFocus : _backgroundColor,
            "& .MuiSvgIcon-root": {
                color: active ? _iconColorFocus : _iconColor,
            },
            "&:hover": {
                backgroundColor: disabled ? _backgroundColor : _backgroundColorFocus,
                "& .MuiSvgIcon-root": {
                    color: disabled ? _iconColor : _iconColorFocus,
                },
            },
        } }, { children: _jsx(IconButton, Object.assign({ disabled: disabled, size: "small" }, { children: _jsx(DeleteIcon, { className: "delIcon" }) })) })));
    return (_jsx(_Fragment, { children: tooltipMsg ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: placement ? placement : "top-end", title: tooltipMsg }, { children: button }))) : (_jsx(_Fragment, { children: button })) }));
};
export default DeleteButton;
