var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { addRepresentation } from "../3dViewer/showFile";
import { findSystemByKey } from "../3dViewer/stateTree";
import { createMolstarModels } from "../3dViewer/createMolstarModels";
export const updateMoleculeReps = (vss, molstar, pyodideWorker, moleculeID) => __awaiter(void 0, void 0, void 0, function* () {
    const loaded_structures = vss.getState().loaded_structures;
    const system = findSystemByKey(loaded_structures, "moleculeID", moleculeID);
    if (!system)
        throw Error(`System with ID ${moleculeID} not found.`);
    const oldCellRefs = system === null || system === void 0 ? void 0 : system.cellRef;
    if (!oldCellRefs)
        throw Error(`System ${system.name} has no cellRefs.`);
    // Delete old reps
    deleteMolstarCells(molstar, oldCellRefs);
    // Create reps of the new molecule (the same reps as before)
    const RepData = yield createNewReps(system, molstar, pyodideWorker, moleculeID);
    if (!RepData)
        return;
    const { cellRefs: newCellRefs, addedRepsParams } = RepData;
    // TODO: strict separation of returned types in createNewReps()
    yield updateRefsInSystemsTree(vss, moleculeID, newCellRefs, addedRepsParams);
});
function createNewReps(system, molstar, pyodideWorker, moleculeID) {
    return __awaiter(this, void 0, void 0, function* () {
        const mol = yield pyodideWorker.RunPythonAsync({
            context: {},
            transfer: true,
            script: `
      getMoleculeTopologyAndCoordinates(systems["${moleculeID}"]["molecule"])
      `,
            debug: false,
        });
        const cellRefs = yield createMolstarModels(molstar, mol, system.name, system.type);
        system.cellRef = cellRefs;
        const addedReps = yield addRepresentation(molstar, pyodideWorker, system, false, moleculeID, system.type === "sdf", mol);
        return { cellRefs: cellRefs, addedRepsParams: addedReps };
    });
}
function deleteMolstarCells(molstar, cellRefs) {
    cellRefs.forEach((ref) => __awaiter(this, void 0, void 0, function* () {
        yield PluginCommands.State.RemoveObject(molstar, {
            state: molstar.state.data,
            ref: ref,
        });
    }));
}
function updateRefsInSystemsTree(vss, moleculeID, newCellRefs, addedRepsParams) {
    return __awaiter(this, void 0, void 0, function* () {
        const loaded_structures = vss.getState().loaded_structures;
        const set_loaded_structures = vss.getState().set_loaded_structures;
        const updatedStructureTree = yield produce(loaded_structures, function (draft) {
            return __awaiter(this, void 0, void 0, function* () {
                const system = findSystemByKey(draft, "moleculeID", moleculeID);
                if (system) {
                    system.cellRef = newCellRefs;
                    system.representations = addedRepsParams;
                }
            });
        });
        set_loaded_structures(updatedStructureTree);
    });
}
