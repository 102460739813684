var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PlotClickAction, PlotHoverAction, } from "../..";
import { setVisibility } from "../../3dViewer/VisibilityToggle";
import { highlightStructure } from "../..//utils/HighlightStructure";
import { createNewSystemFromDC, createNewSystemFromLocal, createNewSystemFromLocalTrajFrames, } from "../../FileLoaders/renderSystem";
import { findSystemFileInTree, focusOnSystem } from "../../Tables/utils";
import { findSystemByKey, updateStateTreeVisibilityFromCellRefs, } from "../../3dViewer/stateTree";
import urlJoin from "url-join";
import { dispatchNotificationEvent } from "../../NotificationSystem/utils";
import { applyActionOnPlotNodes } from "./utils";
import { EchartsActions } from "./interfaces";
function findOrLoadFrames(framesToLoad, vss, molstar, pyodide, NAPGenericStore, localBasePath) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const modelPath = framesToLoad.files[0].model;
        let absPath;
        if (modelPath.charAt(0) !== "/") {
            absPath = urlJoin(localBasePath, modelPath);
        }
        else {
            absPath = modelPath;
        }
        const s = vss.getState().loaded_structures;
        const systName = (_a = framesToLoad.system) === null || _a === void 0 ? void 0 : _a.name;
        let syst;
        if (systName) {
            syst = findSystemByKey(s, "name", systName);
        }
        else {
            syst = findSystemFileInTree(s, absPath); // todo: Define file name in plot data and find system based on it
        }
        if (syst === null || syst === void 0 ? void 0 : syst.cellRef) {
            return { system: syst, isNew: false };
        }
        else {
            if (NAPGenericStore.getState().loadingDCFile) {
                dispatchNotificationEvent({
                    type: "warning",
                    message: "This file cannot be loaded yet, please wait.",
                });
                return;
            }
            NAPGenericStore.getState().setLoadingDCFile(true);
            const newSyst = yield createNewSystemFromLocalTrajFrames(vss, molstar, pyodide, framesToLoad, localBasePath);
            NAPGenericStore.getState().setLoadingDCFile(false);
            return { system: newSyst, isNew: true };
        }
        // const newSyst = await createNewSystemFromLocalTrajFrames(
        //   vss,
        //   molstar,
        //   pyodide,
        //   systPath
        // );
        return undefined;
    });
}
function findOrLoadSystem(systPath, vss, molstar, pyodide, localBasePath, doNotLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        let absPath;
        if (localBasePath && systPath.charAt(0) !== "/") {
            absPath = urlJoin(localBasePath, systPath);
        }
        else {
            absPath = systPath;
        }
        const s = vss.getState().loaded_structures;
        // const systNameMatch = systPath.match(/[^/]+$/);
        // const systName = systNameMatch ? systNameMatch[0] : systPath;
        const syst = findSystemFileInTree(s, absPath);
        if (syst === null || syst === void 0 ? void 0 : syst.cellRef) {
            return syst;
        }
        else if (!doNotLoad) {
            if (systPath.startsWith("dc://")) {
                const newSyst = yield createNewSystemFromDC(vss, molstar, pyodide, systPath);
                return newSyst;
            }
            else if (localBasePath) {
                const newSyst = yield createNewSystemFromLocal(vss, molstar, pyodide, absPath, absPath);
                return newSyst;
            }
        }
    });
}
function findOrLoadSystems(systPaths, vss, molstar, pyodide, localBasePath, doNotLoad) {
    var _a, systPaths_1, systPaths_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const systems = [];
        try {
            for (_a = true, systPaths_1 = __asyncValues(systPaths); systPaths_1_1 = yield systPaths_1.next(), _b = systPaths_1_1.done, !_b;) {
                _d = systPaths_1_1.value;
                _a = false;
                try {
                    const systPath = _d;
                    if (!systPath)
                        continue;
                    const syst = yield findOrLoadSystem(systPath, vss, molstar, pyodide, localBasePath, doNotLoad);
                    if (syst) {
                        systems.push(syst);
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = systPaths_1.return)) yield _c.call(systPaths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return systems;
    });
}
function highlightSystems(systData, vss, molstar, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        if (systData.length === 0)
            return;
        let systems;
        if (typeof systData[0] === "string") {
            systems = yield findOrLoadSystems(systData, vss, molstar, pyodide, undefined, true);
        }
        else {
            const _systData = systData;
            systems = [];
            const s = vss.getState().loaded_structures;
            _systData.forEach((systInfo) => {
                var _a;
                const systName = (_a = systInfo.system) === null || _a === void 0 ? void 0 : _a.name;
                if (systName) {
                    const syst = findSystemByKey(s, "name", systName);
                    if (syst)
                        systems.push(syst);
                }
            });
        }
        if (systems.length > 0) {
            const cellRefsArr = systems
                .map((e) => (e.cellRef ? e.cellRef : undefined))
                .filter((e) => e !== undefined);
            const cellRefs = cellRefsArr.flat();
            highlightStructure(molstar, cellRefs);
        }
    });
}
function displayFrames(framesToLoad, vss, molstar, pyodide, localBasePath, NAPGenericStore) {
    var _a, framesToLoad_1, framesToLoad_1_1;
    var _b, e_2, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            for (_a = true, framesToLoad_1 = __asyncValues(framesToLoad); framesToLoad_1_1 = yield framesToLoad_1.next(), _b = framesToLoad_1_1.done, !_b;) {
                _d = framesToLoad_1_1.value;
                _a = false;
                try {
                    const frameData = _d;
                    const result = yield findOrLoadFrames(frameData, vss, molstar, pyodide, NAPGenericStore, localBasePath);
                    if (result) {
                        const { system, isNew } = result;
                        const cellRefs = system.cellRef;
                        if (!cellRefs)
                            return;
                        const systems = vss.getState().loaded_structures;
                        if (isNew) {
                            focusOnSystem(molstar, cellRefs, systems);
                        }
                        else {
                            const setToVisible = system.visibility === false;
                            setVisibility(molstar, cellRefs, setToVisible);
                            updateStateTreeVisibilityFromCellRefs(vss, cellRefs, setToVisible);
                            if (setToVisible) {
                                focusOnSystem(molstar, cellRefs, systems);
                            }
                        }
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = framesToLoad_1.return)) yield _c.call(framesToLoad_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    });
}
function displaySystems(systPaths, vss, molstar, pyodide, NAPGenericStore, localBasePath, doNotLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        const systems = yield findOrLoadSystems(systPaths, vss, molstar, pyodide, localBasePath, doNotLoad);
        const loadedSystems = systems.filter((syst) => !syst.isLoading);
        const systemsFound = loadedSystems.length > 0;
        if (systemsFound) {
            const setToVisible = loadedSystems.some((s) => s.visibility === false);
            const cellRefsArr = loadedSystems
                .map((e) => (e.cellRef ? e.cellRef : undefined))
                .filter((e) => e !== undefined);
            const cellRefs = cellRefsArr.flat();
            setVisibility(molstar, cellRefs, setToVisible);
            updateStateTreeVisibilityFromCellRefs(vss, cellRefs, setToVisible);
            if (setToVisible) {
                const systems = vss.getState().loaded_structures;
                focusOnSystem(molstar, cellRefs, systems);
            }
            NAPGenericStore.getState().setOpenBottomPanel(false);
            NAPGenericStore.getState().setActiveSystemPosition(undefined);
        }
        return systemsFound;
    });
}
function selectEdgeEndpoints(clickedDataType, nodeNames, chartHandler, vss, molstar, pyodide, NAPGenericStore, dataName, localBasePath) {
    var _a, e_3, _b, _c, _d, e_4, _e, _f;
    var _g;
    return __awaiter(this, void 0, void 0, function* () {
        if (!(clickedDataType === "edge"))
            return;
        if (!chartHandler)
            return;
        const plotOptions = chartHandler.getOption();
        const plotSeries = plotOptions.series;
        if (!plotSeries)
            return;
        try {
            for (var _h = true, plotSeries_1 = __asyncValues(plotSeries), plotSeries_1_1; plotSeries_1_1 = yield plotSeries_1.next(), _a = plotSeries_1_1.done, !_a;) {
                _c = plotSeries_1_1.value;
                _h = false;
                try {
                    const serie = _c;
                    const endNodeIdxs = [];
                    const endNodes = [];
                    // Find nodes at endpoints
                    (_g = serie.data) === null || _g === void 0 ? void 0 : _g.forEach((dataPoint, idx) => {
                        if (nodeNames.includes(dataPoint.name)) {
                            endNodeIdxs.push(idx);
                            endNodes.push(dataPoint);
                        }
                    });
                    // Get data of selected and unselected nodes
                    //@ts-ignore
                    const nodeIsSelected = serie.selectedMap;
                    const selectedNodes = [];
                    const unselectedNodes = [];
                    endNodes.forEach((node) => {
                        const plotData = dataName
                            ? dataName.map((name) => node[name]).filter((e) => e !== undefined)
                            : [];
                        if (nodeIsSelected[node.name])
                            selectedNodes.push(...plotData);
                        else
                            unselectedNodes.push(...plotData);
                    });
                    // Hide or show systems. If all systems are visible, hide all. Otherwise, show thsose that are hidden
                    const allSelected = selectedNodes.length === endNodes.length;
                    const chartAction = allSelected ? "unselect" : "select";
                    let toggleVizSystemFiles = allSelected ? selectedNodes : unselectedNodes;
                    try {
                        for (var _j = true, toggleVizSystemFiles_1 = (e_4 = void 0, __asyncValues(toggleVizSystemFiles)), toggleVizSystemFiles_1_1; toggleVizSystemFiles_1_1 = yield toggleVizSystemFiles_1.next(), _d = toggleVizSystemFiles_1_1.done, !_d;) {
                            _f = toggleVizSystemFiles_1_1.value;
                            _j = false;
                            try {
                                const nodeFile = _f;
                                yield displaySystems([nodeFile], vss, molstar, pyodide, NAPGenericStore, localBasePath, true);
                            }
                            finally {
                                _j = true;
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (!_j && !_d && (_e = toggleVizSystemFiles_1.return)) yield _e.call(toggleVizSystemFiles_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                    // Select or unselect plot nodes
                    chartHandler.dispatchAction({
                        type: chartAction,
                        dataIndex: endNodeIdxs,
                    });
                }
                finally {
                    _h = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (!_h && !_a && (_b = plotSeries_1.return)) yield _b.call(plotSeries_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    });
}
function plotonEventAction(plotClickActions, vss, molstar, pyodide, currentSeriesName, clickedData, clickedDataType, paramIndex, NAPGenericStore, chartHandler, localBasePath) {
    var _a, plotClickActions_1, plotClickActions_1_1;
    var _b, e_5, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        if (!clickedData)
            return;
        try {
            for (_a = true, plotClickActions_1 = __asyncValues(plotClickActions); plotClickActions_1_1 = yield plotClickActions_1.next(), _b = plotClickActions_1_1.done, !_b;) {
                _d = plotClickActions_1_1.value;
                _a = false;
                try {
                    const actionDets = _d;
                    const { action, seriesName, dataName, plotDataType } = actionDets;
                    const isActionSerie = seriesName
                        ? seriesName.some((e) => e.toLowerCase() === currentSeriesName.toLowerCase())
                        : true;
                    if (!isActionSerie)
                        continue;
                    const isDataType = plotDataType
                        ? plotDataType.some((e) => e.toLowerCase() === clickedDataType.toLowerCase())
                        : true;
                    if (!isDataType)
                        continue;
                    const plotData = dataName
                        ? dataName.map((name) => clickedData[name]).filter((e) => e !== undefined)
                        : [];
                    switch (action) {
                        case PlotClickAction.displaySystem:
                            yield displaySystems(plotData, vss, molstar, pyodide, NAPGenericStore, localBasePath);
                            break;
                        case PlotClickAction.displayFrames:
                            if (localBasePath)
                                yield displayFrames(plotData, vss, molstar, pyodide, localBasePath, NAPGenericStore);
                            break;
                        case PlotClickAction.toggleVisibility:
                            const systemsFound = yield displaySystems(plotData, vss, molstar, pyodide, NAPGenericStore, localBasePath, true);
                            if (chartHandler && !systemsFound) {
                                // Unselect node in plot
                                applyActionOnPlotNodes(EchartsActions.unselect, chartHandler, paramIndex);
                            }
                            break;
                        case PlotClickAction.selectEdgeEndpoints:
                            yield selectEdgeEndpoints(clickedDataType, [clickedData.source, clickedData.target], chartHandler, vss, molstar, pyodide, NAPGenericStore, dataName, localBasePath);
                        // plotDataType
                        case PlotHoverAction.highlightSystem:
                            yield highlightSystems(plotData, vss, molstar, pyodide);
                            break;
                        default:
                            break;
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = plotClickActions_1.return)) yield _c.call(plotClickActions_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
    });
}
export function plotonLegendEventAction(plotClickActions, params, vss, molstar, pyodide, NAPGenericStore, plotOptions, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const clickedData = plotOptions.series
            ? plotOptions.series.find((s) => s.name === params.name)
            : undefined;
        yield plotonEventAction(plotClickActions, vss, molstar, pyodide, params.name, clickedData, params.dataType, params.dataIndex, NAPGenericStore, undefined, localBasePath);
    });
}
export function plotonSeriesEventAction(plotClickActions, params, vss, molstar, pyodide, NAPGenericStore, chartHandler, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        if (params.componentType !== "series")
            return;
        yield plotonEventAction(plotClickActions, vss, molstar, pyodide, params.seriesName, params.data, params.dataType, params.dataIndex, NAPGenericStore, chartHandler, localBasePath);
    });
}
