// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Structure } from "molstar/lib/mol-model/structure";
import { PluginStateObject as SO } from "molstar/lib/mol-plugin-state/objects";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
export function cameraFocus(molstar, cellRefs) {
    var _a;
    if (cellRefs.length === 0)
        throw Error("Molstar: empty cellRef array");
    const cellToFocus = molstar.state.data.selectQ((q) => q.byRef(cellRefs[0]));
    if (cellToFocus.length === 0)
        throw new Error("Molstar: q.byRef query failed");
    if (cellToFocus[0].obj) {
        if (SO.Molecule.Structure.is(cellToFocus[0].obj)) {
            const l = Structure.Loci(cellToFocus[0].obj.data);
            molstar.managers.camera.focusLoci([l]);
        }
        if (SO.isRepresentation3D(cellToFocus[0].obj)) {
            const l = (_a = cellToFocus[0].obj) === null || _a === void 0 ? void 0 : _a.data.repr.getAllLoci();
            molstar.managers.camera.focusLoci(l);
        }
        if (SO.Molecule.Trajectory.is(cellToFocus[0].obj)) {
            const subElements = molstar.state.data.selectQ((q) => q
                .byRef(cellRefs[0])
                .subtree()
                .ofType(PluginStateObject.Molecule.Structure));
            if (!subElements || subElements.length === 0)
                throw Error("Molstar: No sub elements found");
            const model = subElements[0];
            const loci = model.obj ? Structure.Loci(model.obj.data) : undefined;
            if (!loci)
                throw Error("Molstar: No loci found");
            molstar.managers.camera.focusLoci([loci]);
        }
    }
}
