import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, } from "@mui/material";
import { useState } from "react";
export const KetcherModal = ({ open, setOpen, initialMolecule, setMolecule, }) => {
    // const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resolve, setResolve] = useState(() => { });
    // const [initialMolecule, setInitialMolecule] = useState<string | undefined>();
    const getKetcher = () => {
        const ketcherContainer = document.getElementById("ifKetcher");
        if (ketcherContainer) {
            //@ts-ignore
            return ketcherContainer.contentWindow.ketcher;
        }
        else
            throw "Ketcher container was not found!";
    };
    const showEditor = () => {
        if (initialMolecule) {
            try {
                const ketcher = getKetcher();
                ketcher.setMolecule(initialMolecule);
            }
            catch (err) {
                console.error(err);
            }
        }
        setLoading(false);
    };
    const set_SMILES = () => {
        /* to see a full list of available operations check ketcher object
            prototype through the console */
        try {
            const ketcher = getKetcher();
            ketcher.getSmilesAsync().then((x) => {
                setMolecule(x);
                // resolve(x);
                setOpen(false);
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: { sx: { minWidth: 800, height: "80%", maxHeight: "700px" } }, hideBackdrop: true }, { children: [_jsx(DialogContent, { children: _jsx("iframe", { id: "ifKetcher", src: "/static/assets/ketcher/index.html", onLoad: showEditor, width: "100%", height: "600px" }) }), _jsxs(DialogActions, { children: [loading && _jsx(CircularProgress, { size: 21 }), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => setOpen(false) }, { children: "Cancel" })), _jsx(Button, Object.assign({ disabled: loading, variant: "contained", onClick: set_SMILES }, { children: "Save" }))] })] })));
};
