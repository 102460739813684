var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { shallow } from "zustand/shallow";
import { FormProvider, useForm } from "react-hook-form";
//@ts-ignore
import googleLogo from "../static/images/googleLogo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, DialogActions, DialogContentText, FormHelperText, Grid, Link, Box, Avatar, } from "@mui/material";
import { TextFieldPM } from "../SharedFormParts/TextField";
import { DCBrowserStore } from "../DatacenterBrowser/datacenterBrowser.store";
import { userAuthStore } from "./userAuthState";
import { defaultValues, LoginSchema } from "./LoginSchema";
import { addQueryParam, handleGoogleAuthError } from "./utils";
import { useCookies } from "react-cookie";
function GoogleAuth() {
    const [, setCookie] = useCookies(["PM_REDIRECT"]);
    const handleGoogleAuhtClick = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(`${window.location.origin}/v2/auth/google/login2`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                // Save url origin to cookies
                const queryParams = new URLSearchParams(window.location.search);
                const from = queryParams.get("from");
                if (from) {
                    setCookie("PM_REDIRECT", from, {
                        path: "/",
                        secure: process.env.NODE_ENV === "development" ? false : true,
                        sameSite: "Strict",
                        maxAge: 86400,
                    });
                }
                // Redirect to url
                const r = yield response.json();
                const url = addQueryParam(r.data.url, "prompt", "select_account");
                if (!url) {
                    console.error(`Invalid URL: ${url}`);
                    handleGoogleAuthError();
                    return;
                }
                window.location.href = url;
            }
            else {
                const respJson = yield response.json();
                handleGoogleAuthError(respJson.message);
            }
        }
        catch (e) {
            console.error(e);
            handleGoogleAuthError();
        }
    });
    return (_jsx(Box, Object.assign({ sx: {
            display: "flex",
            justifyContent: "center",
        } }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(Avatar, { src: googleLogo, style: {
                    width: "1.2rem",
                    height: "1.2rem",
                } }), onClick: handleGoogleAuhtClick, sx: {
                textTransform: "none",
                borderRadius: "1rem",
                color: "#3c4043",
                border: "1px solid  #dadce0",
            } }, { children: "Continue with Google" })) })));
}
export function LoginForm({ onSuccess, onRegisterClick, }) {
    var _a, _b;
    const { login, onSuccessfulLogin } = userAuthStore((state) => ({
        login: state.login,
        onSuccessfulLogin: state.onSuccessfulLogin,
    }));
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    const [userError, setUserError] = useState("");
    const [googleToken, setGoogleToken] = useState({});
    const formMethods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: defaultValues,
    });
    const { handleSubmit, register, reset, formState: { errors }, } = formMethods;
    const resetLoginForm = reset;
    const onSubmit = (data) => {
        resetLoginForm();
        login(data.email, data.password).then((userInfo) => {
            if (userInfo) {
                const onCloseCb = () => {
                    setUserError("");
                    onSuccess();
                };
                onSuccessfulLogin(project, onCloseCb);
            }
            else {
                setUserError("Wrong email or password.");
            }
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(GoogleAuth, {}) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Divider, { children: "or" }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(FormProvider, Object.assign({}, formMethods, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { formKey: "email", label: "Email", error: (_a = errors["email"]) === null || _a === void 0 ? void 0 : _a.message }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { type: "password", formKey: "password", label: "Password", error: (_b = errors["password"]) === null || _b === void 0 ? void 0 : _b.message }) })), userError ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: userError })) }))) : null, _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", type: "submit" }, { children: "Submit" })) })) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ variant: "body1", textAlign: "center" }, { children: _jsx(Link, Object.assign({ component: "button", underline: "hover", variant: "body1", onClick: onRegisterClick }, { children: "I don't have an account." })) })) }))] })) })) })) }))] })) }));
}
