import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Button, Typography, Tooltip } from "@mui/material";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import { useRef, useState } from "react";
function cleanString(str) {
    return str.replace(/^(?:\.\.?\/?)/, "");
}
export function TreeNodeVisibilityButton({ onClick, name, active, isGroup, onMouseEnter, onMouseLeave, isHighlighted, }) {
    const textRef = useRef(null);
    const [enableTooltip, setEnableTooltip] = useState(false);
    const handleMouseOver = () => {
        var _a, _b;
        const cw = (_a = textRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
        const sw = (_b = textRef.current) === null || _b === void 0 ? void 0 : _b.scrollWidth;
        if (cw && sw)
            setEnableTooltip(sw > cw);
    };
    return (_jsx(Button, Object.assign({ size: "small", onClick: onClick, sx: {
            width: "100%",
            height: "100%",
            cursor: "pointer",
            borderRadius: 1,
            transition: "background 0.5s, color 0.5s",
            backgroundColor: active ? "#d9d9d9" : "white",
            "& .MuiTypography-root": {
                color: active ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.3)",
            },
            "& .MuiSvgIcon-root": {
                color: active ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.3)",
            },
            "&:hover": {
                backgroundColor: active
                    ? "rgba(0, 0, 0, 0.12)"
                    : "rgba(0, 0, 0, 0.04)",
            },
        }, onMouseOver: handleMouseOver, onMouseEnter: () => {
            if (onMouseEnter)
                onMouseEnter();
        }, onMouseLeave: () => {
            if (onMouseLeave)
                onMouseLeave();
        } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", wrap: "nowrap", spacing: 1 }, { children: [isGroup ? (_jsx(Grid, Object.assign({ item: true, display: "flex" }, { children: _jsx(FolderIcon, { sx: { fontSize: "1.25rem", mb: "0.15rem" } }) }))) : null, _jsx(Grid, Object.assign({ item: true, zeroMinWidth: true }, { children: _jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: name, disableHoverListener: !enableTooltip, placement: "top" }, { children: _jsx(Typography, Object.assign({ ref: textRef, noWrap: true, sx: {
                                textTransform: "none",
                                textAlign: "left",
                                width: "100%",
                                direction: "rtl",
                                whiteSpace: "nowrap",
                                fontWeight: isHighlighted ? "bold" : undefined,
                                "&:after": {
                                    position: "absolute",
                                },
                                fontSize: "0.95rem",
                            } }, { children: cleanString(name) })) })) }))] })) })));
}
export default TreeNodeVisibilityButton;
