var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useEffect, useState } from "react";
import { selectSystemByIndexArray, } from "../..";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { Fade, Box, Grid, Typography, TextField, Button } from "@mui/material";
import { AtomSelectionStore } from "./AtomSelectionUtils";
import { getRepresentationTypeOptions, defaultColor, defaultType, } from "./representationParamOptions";
import { OpacitySlider } from "./OpacitySlider";
import { SelectionField } from "./SelectRepresentationParam";
import { RepresentationColorSelector } from "./RepresentationColorSelector";
import { SelectionInfoBtn } from "./SelectionInfoBtn";
import { setVisibilityForSDF } from "../VisibilityToggle";
export function AddRepresentation({ vss, molstar, pyodide, setOpen, setSelectedRepID, moleculeID, NAPGenericStore, isSdf, }) {
    if (!moleculeID)
        return _jsx("div", {});
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(vss.getState().loaded_structures, activeSystemPosition)
        : undefined;
    const strucRefLi = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.cellRef) ? activeSystem.cellRef : [];
    const { submit_query, selection, set_selection, set_molecule, alpha, set_alpha, type, set_type, color, set_color, color_value, set_color_value, set_aromaticBonds, } = AtomSelectionStore();
    const [errorQuery, setErrorQuery] = useState("");
    const [panelFade, setPanelFade] = useState(true);
    useEffect(() => {
        AtomSelectionStore.setState({
            selection: "",
            type: defaultType,
            color: defaultColor,
            color_value: 13421772,
            alpha: 1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function _createNewReps() {
        return __awaiter(this, void 0, void 0, function* () {
            setErrorQuery("");
            let inputError = false;
            if (!selection) {
                setErrorQuery("Please input a selection.");
                inputError = true;
            }
            if (inputError)
                return;
            set_aromaticBonds(activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.aromaticBonds);
            let refs = [];
            for (let i = 0; i < strucRefLi.length; i++) {
                const strucRef = strucRefLi[i];
                set_molecule(strucRef);
                try {
                    /* can enforce type since "undef's" are caught at the top */
                    const rep = yield submit_query(molstar, pyodide, moleculeID, isSdf, i);
                    refs.push({ cellRef: strucRef, repRef: rep.repRef });
                }
                catch (error) {
                    setErrorQuery(error);
                    return;
                }
            }
            setVisibilityForSDF(molstar, activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.visibleElementIDs, refs);
            if (activeSystemPosition) {
                const repParamsDat = {
                    selection: selection,
                    alpha: alpha,
                    color: color,
                    color_value: color_value,
                    type: type,
                    visibility: true,
                    aromaticBonds: activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.aromaticBonds,
                    refs: refs,
                };
                vss.getState().add_representation(activeSystemPosition, repParamsDat);
            }
            //Set new selected reference as the new one (thus, the last representation of the structure)
            const reprList = molstar.state.data.selectQ((q) => q
                .byRef(strucRefLi[0])
                .subtree()
                .ofType(PluginStateObject.Molecule.Structure.Representation3D));
            if (reprList)
                setSelectedRepID(reprList.length - 1);
            setOpen(false);
        });
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            _createNewReps();
        }
    };
    return (_jsx(Fade, Object.assign({ in: panelFade }, { children: _jsxs(Box, Object.assign({ sx: { my: 3, mx: 2 } }, { children: [_jsxs(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "h6" }, { children: "New Representation" })) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsxs(Grid, Object.assign({ container: true, item: true, direction: "row", alignItems: "flex-end", justifyContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextField, { size: "small", onKeyDown: handleKeyDown, value: selection, label: "Selection", fullWidth: true, onChange: (e) => set_selection(e.target.value), error: errorQuery ? true : false, helperText: errorQuery, inputProps: {
                                                style: {
                                                    fontSize: "0.875rem",
                                                },
                                            } }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(SelectionInfoBtn, {}) }))] })) }))] })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(SelectionField, { currValue: type, menuLabel: "Representation Type", options: getRepresentationTypeOptions(isSdf), callback: (e) => {
                            set_type(e.target.value);
                        } }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(RepresentationColorSelector, { onSchemeSelection: (e) => {
                            set_color(e);
                        }, onColorPickerUpdate: (e) => set_color_value(e), currColorScheme: color, currColorVal: color_value }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(OpacitySlider, { currVal: alpha, onUpdate: (e) => {
                            set_alpha(e);
                        } }) })), _jsxs(Box, Object.assign({ display: "flex", sx: { paddingInline: 1 }, justifyContent: "flex-end" }, { children: [_jsx(Button, Object.assign({ sx: { marginRight: 2 }, variant: "outlined", color: "secondary", onClick: () => {
                                setPanelFade(false);
                                setTimeout(() => setOpen(false), 250);
                            } }, { children: "cancel" })), _jsx(Button, Object.assign({ variant: "contained", onClick: () => _createNewReps() }, { children: "ok" }))] }))] })) })));
}
