var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from "wouter";
import { useEffect, useState } from "react";
import { flushFolderInVFS } from "../apps/utils";
import { TargetSummaryComp } from "./TargetSummary";
import { displaySessionFiles, loadSessionFiles } from "./loadSessionFromVFS";
import { TargetResults } from "./TargetResults";
import { shallow } from "zustand/shallow";
import { DataPortalStore } from "./dataportal.store";
import { downloadTargetData } from "./utils";
export const DataPortal = ({ pyodide, pyodideReady, NAPGenericStore, vss, molstar, }) => {
    const [currLoc, setLocation] = useLocation();
    const isActive = currLoc.startsWith("/data");
    const [targetID, setTargetID] = useState(undefined);
    const [targetSummaryData, setTargetSummaryData] = useState(undefined);
    const { activeSession, setActiveSession } = DataPortalStore((state) => ({
        activeSession: state.activeSession,
        setActiveSession: state.setActiveSession,
    }), shallow);
    const resultsDir = "/data-portal";
    const getTargetData = () => __awaiter(void 0, void 0, void 0, function* () {
        //Delete previous data from VFS  if any
        flushFolderInVFS(pyodide, resultsDir);
        if (!targetID) {
            setTargetSummaryData(undefined);
            return;
        }
        const targetData = yield downloadTargetData(resultsDir, targetID, pyodide);
        if (!targetData) {
            setTargetSummaryData(null);
            return;
        }
        setTargetSummaryData(targetData);
        const sessions = targetData.datasets.map((dataset) => dataset.session);
        loadSessionFiles(sessions, targetID, resultsDir, pyodide, NAPGenericStore, vss, molstar);
    });
    useEffect(() => {
        const splitLoc = currLoc.split("/");
        const newTargetID = isActive && splitLoc.length > 1 ? splitLoc[2].toUpperCase() : undefined;
        if (!newTargetID)
            setLocation("/");
        setTargetID(newTargetID);
    }, [currLoc]);
    useEffect(() => {
        if (!pyodideReady)
            return;
        getTargetData();
    }, [targetID, pyodideReady]);
    const [setDataVizDetails] = NAPGenericStore((state) => [state.setDataVizDetails], shallow);
    const onResultSelection = (results) => __awaiter(void 0, void 0, void 0, function* () {
        const sessionRelPath = results.session;
        setActiveSession(sessionRelPath);
        //check if system is already loaded
        setDataVizDetails({ description: results.description, name: results.name });
        //Load systems in session file
        yield displaySessionFiles(sessionRelPath, targetID, NAPGenericStore, vss, molstar);
        // setLoadingResults(false);
    });
    const onResultClose = () => {
        setActiveSession(undefined);
        NAPGenericStore.getState().setActiveSystemPosition(undefined);
        NAPGenericStore.getState().setOpenBottomPanel(false);
        // hidePreviousData();
    };
    return (isActive && (_jsxs(_Fragment, { children: [_jsx(TargetResults, { handleClickBack: onResultClose, 
                // disabled={loadingResults}
                NAPGenericStore: NAPGenericStore, show: isActive && !!activeSession, vss: vss, molstar: molstar, pyodide: pyodide }), _jsx(TargetSummaryComp, { targetID: targetID, targetData: targetSummaryData, onResultSelection: onResultSelection, 
                // disabled={loadingResults}
                show: isActive && !activeSession })] })));
};
