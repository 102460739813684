import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { DeleteButton, TreeNodeVisibilityButton } from "../..";
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Grid, Paper, } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export function SystemGroupAccordion({ accordionIsExpanded, toggleAccordion, handleVisibilityClick, groupVisibility, handleDeleteGroupClick, children, name, }) {
    return (_jsxs(Accordion, Object.assign({ sx: {
            maxWidth: "100%",
            "&:before": {
                display: "none",
            },
            "& .MuiAccordionDetails-root": { pt: 0, pr: 0, pb: 0, pl: 2 },
            "& .MuiAccordionSummary-root": { minHeight: 0 },
            backgroundColor: "transparent",
        }, disableGutters: true, elevation: 0, expanded: accordionIsExpanded }, { children: [_jsx(AccordionSummary, Object.assign({ sx: {
                    p: 0,
                    "& .MuiAccordionSummary-content": {
                        pr: "8px",
                        ml: 0,
                        my: "0",
                        width: "100%",
                    },
                } }, { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "left", wrap: "nowrap" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(IconButton, Object.assign({ "aria-label": "toggle-accordion", onClick: toggleAccordion, size: "small", sx: { color: "primary.light" } }, { children: accordionIsExpanded ? _jsx(ArrowDropDownIcon, {}) : _jsx(ArrowRightIcon, {}) })) })), _jsxs(Grid, Object.assign({ container: true, item: true, zeroMinWidth: true, wrap: "nowrap", component: Paper }, { children: [_jsx(Grid, Object.assign({ item: true, zeroMinWidth: true, xs: true }, { children: _jsx(TreeNodeVisibilityButton, { onClick: handleVisibilityClick, name: name, active: groupVisibility, isGroup: true }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(DeleteButton, { onClick: handleDeleteGroupClick, tooltipMsg: "Delete Group", confirmationMsg: `Are you sure you want to delete group "${name}" ?`, placement: "right" }) }))] }))] })) })), _jsx(AccordionDetails, Object.assign({ sx: { ml: 1, pt: 0 } }, { children: children }))] })));
}
