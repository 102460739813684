// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
export const FEPGraphStore = create()((set, get) => ({
    results: [],
    isFullscreen: false,
    isClickDisabled: false,
    toggleFullscreen: () => set((state) => ({ isFullscreen: !state.isFullscreen })),
    setIsClickDisabled: (x) => set({ isClickDisabled: x }),
    showGraph: false,
    setShowGraph: (x) => set(() => ({ showGraph: x })),
    selectedNodeIds: [],
    addSelectedNodeId: (x) => set(() => ({ selectedNodeIds: [...get().selectedNodeIds, x] })),
    setSelectedNodeId: (x) => set(() => ({ selectedNodeIds: x })),
    removeSelectedNodeId: (x) => set(() => ({
        selectedNodeIds: get().selectedNodeIds.filter((e) => e !== x),
    })),
}));
