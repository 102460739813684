import { jsx as _jsx } from "react/jsx-runtime";
import { ActionIconButton } from "../..";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddCircle from "@mui/icons-material/AddCircle";
export const AddButton = ({ onClick, tooltipMsg, placement, }) => {
    return (_jsx(ActionIconButton, Object.assign({ onClick: onClick, tooltipMsg: tooltipMsg, tooltipPlacement: placement, backgroundColor: "#0b5394", iconColor: "white", backgroundHoverColor: "#ffab40", iconHoverColor: "#0b5394" }, { children: _jsx(AddCircle, { className: "addIcon" }) })));
};
export const VisibilityButton = ({ onClick, visible, tooltipMsg, placement, }) => {
    return (_jsx(ActionIconButton, Object.assign({ onClick: onClick, tooltipMsg: tooltipMsg, tooltipPlacement: placement }, { children: visible ? (_jsx(VisibilityIcon, { className: "visibIcon" })) : (_jsx(VisibilityOffIcon, { className: "visibIcon" })) })));
};
