import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Tooltip, Button, Dialog, DialogTitle, DialogContent, Paper, Typography, Divider, Grid, } from "@mui/material";
import { useState } from "react";
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function CitationDialog({ dialogOpen, setDialogOpen, citations, appName, }) {
    const handleClose = () => {
        setDialogOpen(false);
    };
    const [justCopied, setJustCopied] = useState(false);
    return (_jsxs(Dialog, Object.assign({ open: dialogOpen, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsxs(DialogTitle, Object.assign({ id: "alert-dialog-title" }, { children: [capitalizeFirstLetter(appName), " Citation"] })), _jsx(DialogContent, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: citations.map((citation) => (_jsxs(Grid, Object.assign({ container: true, item: true, direction: "column", spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: true }, { children: [_jsx(Button, Object.assign({ sx: { mb: 1 }, variant: "outlined", color: "primary", href: citation.url, target: "_blank", rel: "noreferrer" }, { children: "View the published article" })), _jsx(Tooltip, Object.assign({ title: justCopied ? "Citation copied" : "Click to copy", placement: "top", onClose: () => setJustCopied(false) }, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: { p: 1 }, onClick: () => {
                                                navigator.clipboard.writeText(citation.bibtex);
                                                setJustCopied(true);
                                            } }, { children: _jsx(Typography, Object.assign({ sx: { fontFamily: "Monospace" } }, { children: citation.bibtex })) })) }))] })), citations.length > 1 && _jsx(Divider, { sx: { p: 2 } })] }), citation.url))) })) })] })));
}
export function CiteButton({ citations, appName, }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    let _citations = Array.isArray(citations) ? citations : [citations];
    _citations = _citations.filter((citation) => citation.bibtex || citation.url);
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    if (_citations.length === 0)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ size: "small", variant: "text", endIcon: _jsx(FormatQuoteIcon, {}), color: "secondary", onClick: handleClickOpen }, { children: "Cite" })), _jsx(CitationDialog, { appName: appName, dialogOpen: dialogOpen, setDialogOpen: setDialogOpen, citations: _citations })] }));
}
