const h = "calc(100vh + 2px)";
const rightPanel = {
    backgroundColor: "white",
    position: "fixed",
    display: "block",
    width: "40vw",
    top: "-1px",
    height: h,
    right: 0,
};
const systemTree = {
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `23em`,
    top: "-1px",
    zIndex: 1,
};
const leftPanel = {
    // borderRight: "1px solid #DCDCDC",
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `25em`,
    top: "-1px",
    height: h,
    zIndex: 1,
};
const leftPanelRepresentations = {
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `25em`,
};
const topPanel = {
    backgroundColor: "white",
    position: "fixed",
    display: "block",
    width: "100%",
    height: "30%",
    top: 0,
    overflowY: "auto",
};
const viewer = {
    top: "-1px",
    display: "block",
    position: "fixed",
    height: h,
};
const bottomPanel = {
    backgroundColor: "white",
    position: "fixed",
    display: "block",
    width: "100%",
    height: "25%",
    // right: 0,
    bottom: 0,
};
export { rightPanel, leftPanel, viewer, bottomPanel, topPanel, leftPanelRepresentations, systemTree, };
