import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { shallow } from "zustand/shallow";
import { ListItem, ListItemIcon, Tooltip } from "@mui/material";
import { Panels } from "../GenericViewerState/SharedState";
//@ts-ignore
import logo_white from "../static/images/pm_logo_white.png";
//@ts-ignore
import logo_orange from "../static/images/pm_logo_orange.png";
import { userAuthStore } from "../Authentication/userAuthState";
export const PMLogo = ({ NAPGenericStore, notButton, }) => {
    const [location, setLocation] = useLocation();
    const leftPanelOpen = location.startsWith("/tools");
    const [userInfo] = userAuthStore((state) => [state.userInfo], shallow);
    const isAuthenticated = !!userInfo;
    const redirectToLogin = () => setLocation("/login?from=/tools");
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const togglePanel = () => {
        if (leftPanelOpen)
            setLocation("/"); //i.e. close
        else {
            setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false }));
            setLocation("/tools");
        }
    };
    const logoComp = (_jsx(ListItem, Object.assign({ onClick: notButton ? undefined : isAuthenticated ? togglePanel : redirectToLogin, sx: { cursor: notButton ? undefined : "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx("img", { src: leftPanelOpen ? logo_orange : logo_white, style: { width: "25px", height: "28px" }, alt: "pmlogo" }) }) }), "pm-sidebar-logo"));
    return notButton ? (_jsx(_Fragment, { children: logoComp })) : (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Applications", arrow: true, placement: "right" }, { children: logoComp }), "pm-sidebar-logo-tooltip"));
};
