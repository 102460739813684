var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Redirect, useLocation } from "wouter";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoginForm } from "./Login";
import { RegistrationForm, RegistrationInfo } from "./Registration";
import { userAuthStore } from "./userAuthState";
import { DCBrowserStore } from "../DatacenterBrowser";
import { shallow } from "zustand/shallow";
import { useCookies } from "react-cookie";
import { handleGoogleAuthError } from "./utils";
import { executeWhenPyodideReady } from "../utils/executeWhenPyodideReady";
export function AuthenticationDialog({ isAuthenticated, }) {
    const [currentLocation, setLocation] = useLocation();
    const loginRequested = currentLocation.startsWith("/login");
    const [mode, setMode] = useState("login");
    const queryParams = new URLSearchParams(window.location.search);
    const from = queryParams.get("from");
    const [cookies, , removeCookie] = useCookies(["PM_REDIRECT"]);
    const onSuccess = () => {
        setLocation(from || "/");
        setMode("login");
    };
    const onCloseBtn = () => {
        setLocation("/");
        setTimeout(() => setMode("login"), 200);
    };
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    const { addMissingDataAndSetUserInfo, startWorkerCalls, logout } = userAuthStore((state) => ({
        addMissingDataAndSetUserInfo: state.addMissingDataAndSetUserInfo,
        startWorkerCalls: state.startWorkerCalls,
        logout: state.logout,
    }), shallow);
    const handleGoogleAuthSuccess = () => __awaiter(this, void 0, void 0, function* () {
        const userInfo = yield addMissingDataAndSetUserInfo();
        if (!userInfo) {
            logout(false);
            setLocation("/");
            handleGoogleAuthError();
            return;
        }
        executeWhenPyodideReady(() => startWorkerCalls(userInfo.userId, project));
        const from_cookie = cookies["PM_REDIRECT"];
        setLocation(from_cookie || "/");
        if (from_cookie)
            removeCookie("PM_REDIRECT", {
                path: "/",
                secure: process.env.NODE_ENV === "development" ? false : true,
                sameSite: "Strict",
                maxAge: 86400,
            });
    });
    const handleGoogleAuth = () => __awaiter(this, void 0, void 0, function* () {
        const url = `${window.location.origin}/v2/auth/google/callback?${queryParams}`;
        const response = yield fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            handleGoogleAuthSuccess();
        }
        else {
            const respJson = yield response.json();
            handleGoogleAuthError(respJson.message);
        }
    });
    // Capture google auth call
    useEffect(() => {
        if (currentLocation === "/auth/google/callback")
            handleGoogleAuth();
    }, [currentLocation]);
    const showLogin = mode === "login";
    return (_jsx(_Fragment, { children: isAuthenticated && loginRequested ? (_jsx(Redirect, { to: "/" })) : (_jsxs(Dialog, Object.assign({ open: loginRequested, onClose: onCloseBtn, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ textAlign: "center", variant: "h5" }, { children: [showLogin ? "Log In" : "Register", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: onCloseBtn, sx: {
                                position: "absolute",
                                right: 4,
                                top: 4,
                            } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, { children: showLogin ? (_jsx(LoginForm, { onSuccess: onSuccess, onRegisterClick: () => setMode("registration") })) : (_jsxs(_Fragment, { children: [_jsx(RegistrationForm, { onSuccess: onSuccess, onLoginClick: () => setMode("login") }), _jsx(Divider, { variant: "middle", sx: { my: 2 } }), _jsx(RegistrationInfo, {})] })) })] }))) }));
}
