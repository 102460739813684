import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { KetcherModal } from "../KetcherModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
export function KetcherInput({ molecule, setMolecule, }) {
    const [open, set_open] = useState(false);
    const open_ketcher_modal = () => set_open(true);
    const cleanup_molecule = () => setMolecule("");
    return (_jsxs(_Fragment, { children: [!molecule ? (_jsx(Button, Object.assign({ variant: "contained", component: "label", onClick: open_ketcher_modal, endIcon: _jsx(AddCircleIcon, {}) }, { children: "New Molecule" }))) : (_jsxs(Grid, Object.assign({ container: true, direction: "row", spacing: 1, justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextField, { fullWidth: true, size: "small", InputProps: { readOnly: true }, id: "ketcher-output", margin: "dense", variant: "outlined", label: "Saved Molecule", value: molecule }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ onClick: cleanup_molecule }, { children: _jsx(DeleteIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ onClick: open_ketcher_modal }, { children: _jsx(EditIcon, {}) })) }))] }))), _jsx(KetcherModal, { open: open, setOpen: set_open, setMolecule: setMolecule, initialMolecule: molecule })] }));
}
