// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { create } from "zustand";
import { NAPPlotStore } from "./plots.store";
export const NAPTableStore = create()((set, get) => ({
    tables: [],
    activeTableIdx: 0,
    set_tables: (x) => set({ tables: x }),
    set_activeTableIdx: (x) => set({ activeTableIdx: x }),
    add_table: (x) => {
        set((state) => ({
            tables: [...state.tables, x],
            activeTableIdx: get().tables.length,
        }));
    },
    add_tables: (x) => {
        set((state) => ({
            tables: [...state.tables, ...x],
            activeTableIdx: get().tables.length,
        }));
    },
    del_table: (idx, cb) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            draft.splice(idx, 1);
        });
        if (updated_tables.length === 0 &&
            NAPPlotStore.getState().plots.length === 0)
            cb();
        return { tables: updated_tables, activeTableIdx: 0 };
    }),
}));
