var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useEffect, useState } from "react";
import { createStructureRepresentationParams, } from "molstar/lib/mol-plugin-state/helpers/structure-representation-params";
import { trimText, selectSystemByIndexArray, } from "../..";
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Grid, Divider, } from "@mui/material";
import { removeRepresentation } from "../Controls/utils";
import { SelectVolumeVisualization, SliderVolumeRepresentationParam, SelectVolumeColor, } from "../SelectVolumeParams";
import { updateStateTreeRepresentation, findSystemByKey } from "../stateTree";
import { OpacitySlider } from "./OpacitySlider";
import { AtomSelectionStore } from "./AtomSelectionUtils";
import { RepresentationColorSelector } from "./RepresentationColorSelector";
import { getRepresentationTypeOptions, } from "./representationParamOptions";
import { SelectionInfoBtn } from "./SelectionInfoBtn";
export function SelectRepresentationColor({ vss, molstar, selectedRepID, NAPGenericStore, activeSystem, color, color_value, }) {
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const onSchemeSelection = (newVal) => __awaiter(this, void 0, void 0, function* () {
        yield updateRepresentation(molstar, activeSystem, selectedRepID, {
            color: newVal,
        });
        if (activeSystemPosition)
            updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, "color", newVal);
    });
    const onColorPickerUpdate = (newColor) => __awaiter(this, void 0, void 0, function* () {
        yield updateRepresentation(molstar, activeSystem, selectedRepID, {
            color: "uniform",
            color_value: newColor,
        });
        if (activeSystemPosition)
            updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, "color_value", newColor);
    });
    return (_jsx(RepresentationColorSelector, { onSchemeSelection: onSchemeSelection, onColorPickerUpdate: onColorPickerUpdate, currColorScheme: color, currColorVal: color_value }));
}
export function SelectRepresentationType({ vss, molstar, selectedRepID, NAPGenericStore, activeSystem, isSmallMol, type, }) {
    const [reprType, setReprType] = useState(type);
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    useEffect(() => {
        if (type !== reprType) {
            setReprType(type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    const menuLabel = "Representation Type";
    const options = getRepresentationTypeOptions(!!isSmallMol);
    const callback = (e) => __awaiter(this, void 0, void 0, function* () {
        const newVal = e.target.value;
        yield updateRepresentation(molstar, activeSystem, selectedRepID, {
            type: newVal,
        });
        if (activeSystemPosition)
            updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, "type", newVal);
    });
    return (_jsx(SelectionField, { currValue: reprType, menuLabel: menuLabel, options: options, callback: callback }));
}
function SelectRepresentationOpacitySlider({ vss, molstar, selectedRepID, NAPGenericStore, activeSystem, alpha, }) {
    const [reprOpacity, setReprOpacity] = useState(alpha);
    useEffect(() => {
        if (alpha !== reprOpacity) {
            setReprOpacity(alpha);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alpha]);
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const callback = (newVal) => __awaiter(this, void 0, void 0, function* () {
        yield updateRepresentation(molstar, activeSystem, selectedRepID, {
            alpha: newVal,
        });
        if (activeSystemPosition)
            yield updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, "alpha", newVal); // this also triggers the useEffect above
    });
    return _jsx(OpacitySlider, { currVal: reprOpacity, onUpdate: callback });
}
export function SelectionField({ currValue, menuLabel, options, callback, }) {
    return (_jsx(Box, Object.assign({ sx: { minWidth: 100, marginTop: 2 } }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: menuLabel }), _jsx(Select, Object.assign({ size: "small", value: currValue, label: menuLabel, onChange: (e) => callback(e), inputProps: {
                        style: {
                            fontSize: "0.875rem",
                        },
                    } }, { children: options.map((option) => (_jsx(MenuItem, Object.assign({ value: option.value }, { children: option.label }), option.value))) }))] })) })));
}
function UpdateRepresentationSelection({ vss, molstar, pyodide, selectedRepID, moleculeID, NAPGenericStore, }) {
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = selectSystemByIndexArray(loaded_structures, activeSystemPosition);
    const selectedRepresentation = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem.representations[selectedRepID]
        : undefined;
    const { submit_query, set_molecule } = AtomSelectionStore();
    const [selectionError, setSelectionError] = useState("");
    const currentSelection = selectedRepresentation
        ? selectedRepresentation.selection
        : "";
    const [selectionValue, setSelectionValue] = useState(currentSelection);
    useEffect(() => {
        setSelectionValue(currentSelection);
        setSelectionError("");
    }, [currentSelection]);
    function addEquivalentRepNewSelection(pyodide) {
        var _a, e_1, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!activeSystem ||
                !activeSystem.representations ||
                !selectedRepresentation ||
                !selectedRepresentation.refs)
                return;
            const repParamsDat = Object.assign(Object.assign({}, selectedRepresentation), { selection: selectionValue, refs: [] });
            /*
              Molstar's "uniform" color schemeis shown as "custom color" in the UI,
              when selected we also have to keep track of the selected color value.
            */
            AtomSelectionStore.setState(repParamsDat);
            const repRefs = selectedRepresentation.refs.map((ref) => ref.repRef);
            let new_refs = [];
            try {
                for (var _d = true, _e = __asyncValues(selectedRepresentation.refs.entries()), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                    _c = _f.value;
                    _d = false;
                    try {
                        let [idx, refs] = _c;
                        set_molecule(refs.cellRef);
                        try {
                            if (!moleculeID)
                                throw Error(`molecule ID ${moleculeID} does not exist`);
                            const loaded_structures = vss.getState().loaded_structures;
                            const currentSystem = findSystemByKey(loaded_structures, "moleculeID", moleculeID);
                            const newRep = yield submit_query(molstar, pyodide, moleculeID, (currentSystem === null || currentSystem === void 0 ? void 0 : currentSystem.type) === "sdf", idx);
                            new_refs.push({
                                cellRef: refs.cellRef,
                                repRef: newRep.repRef,
                            });
                        }
                        catch (error) {
                            return error;
                        }
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            repParamsDat.refs = new_refs;
            vss
                .getState()
                .replace_representation(selectedRepresentation.refs[0].cellRef, repRefs[0], repParamsDat);
        });
    }
    const handleKeyDownSelection = (pyodide, e) => __awaiter(this, void 0, void 0, function* () {
        if (e.key === "Enter") {
            if (selectionValue === currentSelection ||
                !selectedRepresentation ||
                !selectedRepresentation.refs)
                return;
            if (!selectionValue) {
                setSelectionError("Please input a selection.");
                return;
            }
            setSelectionError("");
            const oldRepRef = selectedRepresentation.refs.map((ref) => ref.repRef);
            //add new selection in a new representation
            const selectionError = yield addEquivalentRepNewSelection(pyodide);
            if (selectionError) {
                setSelectionError(selectionError);
            }
            else {
                //Remove old representation
                yield removeRepresentation(vss, molstar, oldRepRef, activeSystemPosition, selectedRepID, false);
            }
        }
    });
    return (_jsx(TextField, { value: selectionValue, label: "Selection", size: "small", fullWidth: true, onChange: (e) => {
            setSelectionError("");
            setSelectionValue(e.target.value);
        }, onKeyDown: (e) => handleKeyDownSelection(pyodide, e), error: selectionError ? true : false, helperText: selectionError, inputProps: {
            style: {
                fontSize: "0.875rem",
            },
        } }));
}
export function SelectVolumeParams({ vss, molstar, activeSystem, NAPGenericStore, }) {
    let trimmedText = "";
    if (activeSystem.name)
        trimmedText = trimText(activeSystem.name, 20);
    const volumeRepresentations = activeSystem.volumeRepresentation;
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: { mx: 2 } }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center", spacing: 1 }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "h6" }, { children: trimmedText })) })) })) })), _jsx(Divider, { variant: "middle" }), _jsx(Box, Object.assign({ sx: { my: 3, mx: 2 } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "h6" }, { children: "Parameters" })) })), _jsxs(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: [_jsx(SliderVolumeRepresentationParam, { vss: vss, molstar: molstar, activeSystem: activeSystem, alpha: volumeRepresentations === null || volumeRepresentations === void 0 ? void 0 : volumeRepresentations.alpha, repType: "Opacity", NAPGenericStore: NAPGenericStore }), _jsx(SelectVolumeVisualization, { vss: vss, molstar: molstar, activeSystem: activeSystem, type: volumeRepresentations === null || volumeRepresentations === void 0 ? void 0 : volumeRepresentations.type, NAPGenericStore: NAPGenericStore }), _jsx(SelectVolumeColor, { vss: vss, molstar: molstar, activeSystem: activeSystem, color: volumeRepresentations === null || volumeRepresentations === void 0 ? void 0 : volumeRepresentations.color, NAPGenericStore: NAPGenericStore })] }))] })) }))] }));
}
export function RepresentationParamsSection({ vss, molstar, pyodide, selectedRepID, moleculeID, NAPGenericStore, activeSystem, isSmallMol, }) {
    const selectedRepresentation = activeSystem.representations[selectedRepID];
    return (_jsxs(Box, Object.assign({ sx: { my: 3, mx: 2 } }, { children: [_jsxs(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "h6" }, { children: "Representation Parameters" })) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsxs(Grid, Object.assign({ container: true, item: true, direction: "row", alignItems: "flex-end", justifyContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(UpdateRepresentationSelection, { vss: vss, molstar: molstar, pyodide: pyodide, moleculeID: moleculeID, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(SelectionInfoBtn, {}) }))] })) }))] })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(SelectRepresentationType, { vss: vss, molstar: molstar, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore, isSmallMol: isSmallMol, activeSystem: activeSystem, type: selectedRepresentation.type }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(SelectRepresentationColor, { vss: vss, molstar: molstar, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore, activeSystem: activeSystem, color: selectedRepresentation.color, color_value: selectedRepresentation.color_value }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(SelectRepresentationOpacitySlider, { vss: vss, molstar: molstar, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore, activeSystem: activeSystem, alpha: selectedRepresentation.alpha }) }))] })));
}
function updateRepresentation(molstar, activeSystem, selectedRepID, newRepresentationParams) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (!activeSystem.representations)
            return;
        // Check if the new parameters are different from the current ones
        const currRepresentationParams = activeSystem.representations[selectedRepID];
        let matches = Object.keys(newRepresentationParams).map((key) => {
            return currRepresentationParams[key] === newRepresentationParams[key];
        });
        if (matches.every((match) => match === true))
            return; // if not return
        // Merge old with new parameters
        const finalReprParams = Object.assign(Object.assign({}, currRepresentationParams), newRepresentationParams);
        try {
            (_a = currRepresentationParams.refs) === null || _a === void 0 ? void 0 : _a.forEach((_ref) => __awaiter(this, void 0, void 0, function* () {
                // update molstar
                yield molstar
                    .build()
                    .to(_ref.repRef)
                    .update(createStructureRepresentationParams(molstar, void 0, {
                    type: finalReprParams.type,
                    color: finalReprParams.color,
                    typeParams: {
                        alpha: finalReprParams.alpha,
                        aromaticBonds: activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.aromaticBonds,
                    },
                    colorParams: finalReprParams.color_value
                        ? {
                            value: finalReprParams.color_value,
                            carbonColor: {
                                name: "uniform",
                                // params: { value: Color(0x247d61) },
                            },
                        }
                        : undefined,
                }))
                    .commit();
            }));
        }
        catch (e) {
            console.error("Could not update representation!");
            console.error(e);
        }
    });
}
