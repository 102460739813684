import { jsx as _jsx } from "react/jsx-runtime";
import { Menu } from "@mui/material";
export function SidebarPopupMenu({ handleClose, anchorEl, menuIsOpen, children, arrowPosition, }) {
    const _arrowPosition = arrowPosition ? arrowPosition : "middle";
    return (_jsx(Menu, Object.assign({ id: "basic-menu", 
        // MenuListProps={{ onMouseLeave: handleClose }}
        anchorEl: anchorEl, open: menuIsOpen, onClose: handleClose, anchorOrigin: {
            vertical: "center",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "center",
            horizontal: "left",
        }, PaperProps: {
            sx: {
                overflow: "visible",
                ml: 1,
                "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    bottom: _arrowPosition === "bottom" ? 5 : "calc(50% - 10px)",
                    left: -5,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                },
            },
        } }, { children: children })));
}
