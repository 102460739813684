var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateObjectRef } from "molstar/lib/mol-state";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ControlledSlider } from "../Controls/Slider/ControlledSlider";
import { ControlledInputNumber } from "../Controls/Slider/ControlledInput";
import { debounce_updateFrame } from "../showTraj";
import { updateStateTreeTrajectory } from "../stateTree";
import { PlayPauseButton } from "./PlayPauseButton";
import { CUSTOM_AnimateModelIndex } from "./TrajectoryAnimation";
export function TrajectoryFrameControls({ vss, molstar, trajCellRef, visibility, positionInTree, defaultFrame, defaultStep, }) {
    const trajectory = StateObjectRef.resolveAndCheck(molstar.state.data, trajCellRef);
    const { frameCount, controlParameters, defaultFrameNum, defaultStepNum } = getTrajectoryDefaultParams(trajectory, defaultFrame, defaultStep);
    const [currFrame, setFrame] = useState(defaultFrameNum);
    const [stepSize /*setStepSize*/] = useState(defaultStepNum);
    const [isPlaying, setIsPlaying] = useState(false);
    const onUpdateCallback = (newFrame) => __awaiter(this, void 0, void 0, function* () {
        if (!molstar)
            return;
        if (isPlaying)
            return;
        debounce_updateFrame(molstar, newFrame, trajCellRef);
        setFrame(newFrame);
        updateStateTreeTrajectory(vss, positionInTree, "frame", newFrame);
    });
    const startAnimation = useCallback(() => {
        molstar.managers.animation.play(CUSTOM_AnimateModelIndex, {
            duration: {
                name: "computed",
                params: { targetFps: 8 },
            },
            mode: { name: "once", params: { direction: "forward" } },
            cellRef: trajCellRef,
        });
    }, [trajCellRef, molstar]);
    const stopAnimation = useCallback(() => {
        molstar.managers.animation.stop();
        updateStateTreeTrajectory(vss, positionInTree, "frame", currFrame);
    }, [currFrame, positionInTree, vss, molstar]);
    molstar.state.events.object.updated.subscribe((e) => {
        const state = molstar.state.data;
        if (!state)
            return null;
        const models = state.selectQ((q) => q
            .byRef(trajCellRef)
            .subtree()
            .ofType(PluginStateObject.Molecule.Model)
            .first());
        const idx = models[0].transform
            .params.modelIndex;
        setFrame(idx + 1);
    });
    useEffect(() => {
        isPlaying ? startAnimation() : stopAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying]);
    return (_jsx(Box, Object.assign({ sx: { padding: "5px" } }, { children: _jsxs(Grid, Object.assign({ container: true, 
            // spacing={1}
            direction: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { mr: 2 } }, { children: _jsx(PlayPauseButton, { isPlaying: isPlaying, visibility: visibility, onClick: () => setIsPlaying(!isPlaying) }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Box, Object.assign({ onClick: () => setIsPlaying(false), sx: { minWidth: "5em" } }, { children: _jsx(ControlledSlider, Object.assign({}, controlParameters, { currVal: currFrame, disabled: !visibility, onUpdate: onUpdateCallback, step: stepSize })) })) })), _jsxs(Grid, Object.assign({ item: true, container: true, direction: "row", alignItems: "center", xs: "auto", spacing: 1, sx: { mx: 1 } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Box, Object.assign({ sx: { width: "3em" } }, { children: _jsx(ControlledInputNumber, Object.assign({}, controlParameters, { currVal: currFrame, disabled: !visibility, onUpdate: onUpdateCallback, onlyIntegers: true, step: stepSize })) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ variant: "body2", sx: { color: visibility ? "black" : "#808080" } }, { children: `/ ${frameCount}` })) }))] }))] })) })));
}
const getTrajectoryDefaultParams = (trajectory, defaultFrame, defaultStep) => {
    var _a;
    const frameCount = (_a = trajectory === null || trajectory === void 0 ? void 0 : trajectory.obj) === null || _a === void 0 ? void 0 : _a.data.frameCount;
    if (frameCount === undefined)
        throw new Error("Trajectory frames are undefined.");
    const controlParameters = {
        minVal: 1,
        maxVal: frameCount,
    };
    const defaultFrameNum = defaultFrame && defaultFrame <= frameCount ? defaultFrame : 1;
    const defaultStepNum = defaultStep && defaultStep <= frameCount ? defaultStep : 1;
    return { frameCount, controlParameters, defaultFrameNum, defaultStepNum };
};
