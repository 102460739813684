// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
export const useKeyPress = (keys, callback, ctrlKey, shiftKey, altKey, node) => {
    // implement the callback ref pattern
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });
    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        // check if one of the key is part of the ones we want
        const keyCombinaiton = (!ctrlKey || (ctrlKey && event.ctrlKey)) &&
            (!shiftKey || (shiftKey && event.shiftKey)) &&
            (!altKey || (altKey && event.altKey));
        if (keyCombinaiton && keys.some((key) => event.key === key)) {
            callbackRef.current(event);
        }
    }, [keys, ctrlKey, shiftKey, altKey]);
    useEffect(() => {
        // target is either the provided node or the document
        const targetNode = node !== null && node !== void 0 ? node : document;
        // attach the event listener
        targetNode && targetNode.addEventListener("keydown", handleKeyPress);
        // remove the event listener
        return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node]);
};
