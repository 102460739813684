import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useEffect, useState } from "react";
import { Grid, Box, IconButton, Divider } from "@mui/material";
import { indexSelector, addReverseIndexing } from "../..";
import CloseIcon from "@mui/icons-material/Close";
import { selectSystemByIndexArray, updateStateTreeRepresentation, } from "../stateTree";
import { TypographyWithTooltipIfTruncated } from "../../utils/TypographyWithTooltipIfTruncated";
import { RepresentationParamsSection, SelectVolumeParams, } from "./SelectRepresentationParam";
import { RepresentationListWithActions } from "./SystemRepresentations";
import { AddRepresentation } from "./AddRepresentation";
import { setVisibility } from "../VisibilityToggle";
// Representations panel for structural files (pdb, sdf, etc.)
function GeneralRepresentationControlsPanel({ vss, molstar, pyodide, activeSystemPosition, NAPGenericStore, }) {
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(activeSystemPosition, loaded_structures)
        : undefined;
    const [showAddRepPanel, setShowAddRepPanel] = useState(false);
    const [selectedRepID, setSelectedRepID] = useState(0);
    // Visibility indicator in the UI.
    const [repIsHidden, setRepIsHidden] = useState(false);
    const strucRefLi = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.cellRef) ? activeSystem.cellRef : [];
    useEffect(() => {
        // When the active system changes, select the 1st representation and hide the "add representation" panel
        // Also when the cellRefs of the active system change
        if (activeSystemPosition !== undefined) {
            setSelectedRepID(0);
            setShowAddRepPanel(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSystemPosition, strucRefLi.join(".")]);
    const selectedRepresentation = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations[selectedRepID]
        : undefined;
    const num_reps = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations.length
        : 0;
    useEffect(() => {
        //set when there is a change in the active molecule, selected representation, or number of representations
        if (selectedRepID >= num_reps || !selectedRepresentation)
            return;
        setRepIsHidden(!selectedRepresentation.visibility);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSystemPosition, selectedRepID, num_reps]);
    const handleToggleRepVisibility = () => {
        if (!selectedRepresentation || !selectedRepresentation.refs)
            return;
        let repRef = selectedRepresentation.refs.map((ref) => ref.repRef);
        if (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.visibleElementIDs) {
            // Handle the SDF case where only specific molecules are shown
            try {
                repRef = indexSelector(activeSystem.visibleElementIDs, addReverseIndexing(repRef));
            }
            catch (_a) { }
        }
        setVisibility(molstar, repRef, repIsHidden);
        setRepIsHidden(!repIsHidden);
        if (activeSystemPosition)
            updateStateTreeRepresentation(vss, activeSystemPosition, selectedRepID, "visibility", repIsHidden);
    };
    const systName = (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.name) ? activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.name : "";
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: { mb: 3, mx: 2 } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, sx: { width: "22em" } }, { children: _jsx(TypographyWithTooltipIfTruncated, { arrow: true, 
                                // enterDelay={1000}
                                title: systName, placement: "top", typopgraphyText: systName, typographyGutterBottom: true, typographyVariant: "h6", truncateStart: true }) })), _jsx(RepresentationListWithActions, { vss: vss, molstar: molstar, selectedRepID: selectedRepID, setSelectedRepID: setSelectedRepID, handleToggleRepVisibility: handleToggleRepVisibility, showAddRepPanel: showAddRepPanel, setShowAddRepPanel: setShowAddRepPanel, NAPGenericStore: NAPGenericStore })] })) })), _jsx(Divider, { variant: "middle" }), showAddRepPanel ? (_jsx(AddRepresentation, { vss: vss, pyodide: pyodide, molstar: molstar, setOpen: setShowAddRepPanel, setSelectedRepID: setSelectedRepID, moleculeID: activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.moleculeID, isSdf: (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.type) === "sdf", NAPGenericStore: NAPGenericStore })) : (selectedRepresentation && (_jsx(RepresentationParamsSection, { vss: vss, pyodide: pyodide, molstar: molstar, moleculeID: activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.moleculeID, selectedRepID: selectedRepID, NAPGenericStore: NAPGenericStore, activeSystem: activeSystem, isSmallMol: (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.type) === "sdf" })))] }));
}
export function RepresentationControlsPanel({ vss, molstar, pyodide, NAPGenericStore, }) {
    const [activeSystemPosition, setActiveSystemPosition, activePanels, setOpenBottomPanel,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state.setActiveSystemPosition,
        state._activePanels,
        state.setOpenBottomPanel,
    ], shallow);
    // Close reps panel when systems panel is closed
    const show = activePanels.statetree;
    useEffect(() => {
        if (!show) {
            closePanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    const [systemTree] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(activeSystemPosition, systemTree)
        : undefined;
    const closePanel = () => {
        setActiveSystemPosition(undefined);
        setOpenBottomPanel(false);
    };
    useEffect(() => {
        function handleEsc(event) {
            if (event.key === "Escape") {
                closePanel();
            }
        }
        document.addEventListener("keydown", handleEsc);
        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
    // div ref={myRef}
    _jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: closePanel }, { children: _jsx(CloseIcon, {}) })) })), (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.type) === "volume" ? (_jsx(SelectVolumeParams, { vss: vss, molstar: molstar, activeSystem: activeSystem, NAPGenericStore: NAPGenericStore })) : (_jsx(GeneralRepresentationControlsPanel, { vss: vss, pyodide: pyodide, molstar: molstar, activeSystemPosition: activeSystemPosition, NAPGenericStore: NAPGenericStore }))] }));
}
