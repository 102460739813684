// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//

import {
  DCBrowserStore,
  PriorityWorkerMessage,
  jobListStore,
  AppListStore,
  applyLimitations,
  userAuthStore,
} from "molkitstar-ui";

/**
 * Takes all messages from the worker and fires suitable callbacks based on
 * the message types.
 */
export function priorityWorkerHandler(e: MessageEvent) {
  const msg = e.data as PriorityWorkerMessage;

  switch (msg.type) {
    case "job-list":
      jobListStore.getState().setJobList(msg.jobList);
      break;

    case "app-list":
      const manifests = JSON.parse(msg.appList);
      const limits = JSON.parse(msg.limits);

      applyLimitations(manifests, limits);

      AppListStore.getState().setAppLimits(limits);
      AppListStore.getState().setAppList(manifests);
      /*
        Run all the python function definitions 
        so that they are callable at job submission.
      */
      window.pyodideWorker.RunPythonAsync({
        context: {},
        script: limits.python_functions,
      });
      break;

    case "datasets":
      const datasets = msg.datasets;
      const filetree = DCBrowserStore.getState().createFiletree(
        datasets,
        `${msg.truncate}/`,
        msg.project
      );
      DCBrowserStore.getState().setDatacenterEntries(filetree);
      const currentFolderId = DCBrowserStore.getState().currentFolderId;
      const currentDirIsRoot =
        !currentFolderId || currentFolderId === filetree.rootFolderId;
      if (currentDirIsRoot || msg.setCurrentDir) {
        DCBrowserStore.getState().updateFolderChain(
          filetree,
          filetree.rootFolderId
        );
        DCBrowserStore.getState().getCurrentFolderContent(
          filetree,
          filetree.rootFolderId
        );
      }

      break;
    case "example-file":
      let content;
      let blobtype;

      if (typeof msg.fileContent === "string") {
        content = msg.fileContent;
        blobtype = "text/plain";
      } else {
        content = new Uint8Array(msg.fileContent);
        blobtype = "application/zip";
      }
      const blob = new Blob([content], {
        type: blobtype,
      });
      const url = URL.createObjectURL(blob);

      const anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = msg.fileName;
      anchorElement.click();

      window.URL.revokeObjectURL(url);
      break;
    case "projects":
      let projects = msg.projects;
      projects.sort((a, b) => {
        // Ensure "default_project" is always the first element
        if (a === "default_project") return -1;
        if (b === "default_project") return 1;
        return a.localeCompare(b);
      });
      DCBrowserStore.getState().setSessionProjects(projects);
      break;
    case "queue-status":
      jobListStore.getState().updateQueueStatus(msg.queueJobs);
      break;
    case "logout":
      userAuthStore.getState().logout(true);
      break;
    default:
      console.error(
        `priorityWorkerHandler() received message of type ${msg.type}!`
      );
      break;
  }
}
