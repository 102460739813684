import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { TextField, Input } from "@mui/material";
export function ControlledInput({ currVal, minVal, maxVal, disabled, onUpdate, onlyIntegers, }) {
    const [internalValue, setInternalValue] = useState(currVal.toString());
    useEffect(() => {
        if (internalValue !== currVal.toString())
            setInternalValue(currVal.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currVal]);
    const [inputErr, setInputErr] = useState(false);
    useEffect(() => {
        const re = onlyIntegers ? /^[-+]?[0-9]*$/ : /^[-+]?[0-9]+(\.)*[0-9]*$/;
        const valid = internalValue.match(re);
        const thingsWentWrong = !valid ||
            Number(internalValue) > maxVal ||
            Number(internalValue) < minVal;
        if (thingsWentWrong)
            setInputErr(true);
        else
            setInputErr(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue, minVal, maxVal]);
    const helpTxt = `Must be between ${minVal} and ${maxVal}`;
    const style = Object.assign(Object.assign({}, inputStyle(disabled)), { padding: "7px" });
    return (_jsx(TextField, { value: internalValue, size: "small", type: "text", disabled: disabled, error: inputErr, helperText: inputErr ? helpTxt : "", onChange: (e) => setInternalValue(e.target.value), inputProps: { style: style }, onKeyDown: (e) => {
            if (e.key === "Enter" && !inputErr) {
                onUpdate(Number(internalValue));
            }
        } }));
}
const inputStyle = (disabled) => {
    return {
        fontSize: "0.875rem",
        color: disabled ? "#808080" : "black",
    };
};
export function ControlledInputNumber({ currVal, minVal, maxVal, disabled, onUpdate, onlyIntegers, step, }) {
    const [internalValue, setInternalValue] = useState(currVal.toString());
    useEffect(() => {
        if (internalValue !== currVal.toString())
            setInternalValue(currVal.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currVal]);
    const [inputErr, setInputErr] = useState(false);
    useEffect(() => {
        const re = onlyIntegers ? /^[-+]?[0-9]*$/ : /^[-+]?[0-9]+(\.)*[0-9]*$/;
        const valid = internalValue.match(re);
        const thingsWentWrong = !valid ||
            Number(internalValue) > maxVal ||
            Number(internalValue) < minVal;
        if (thingsWentWrong)
            setInputErr(true);
        else {
            setInputErr(false);
            onUpdate(Number(internalValue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue, minVal, maxVal]);
    return (_jsx(Input
    // sx={{ mr: 1, ml: 1 }}
    , { 
        // sx={{ mr: 1, ml: 1 }}
        error: inputErr, value: internalValue, size: "small", onChange: (e) => setInternalValue(e.target.value), disabled: disabled, inputProps: {
            step: step ? step : 1,
            min: 1,
            max: maxVal,
            type: "number",
            "aria-labelledby": "input-slider",
            style: inputStyle(disabled),
        } }));
}
