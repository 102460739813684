import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { Fade, Box } from "@mui/material";
import { Documentation } from "../../Documentation/Documentation";
import { leftPanel } from "../panelDimensions";
export function DocsPanel({ pyodide, desktopSections, }) {
    const [parentLocation] = useLocation();
    const isActive = parentLocation.startsWith("/docs");
    const dimensions = Object.assign(Object.assign({}, leftPanel), { left: (theme) => `calc(${theme.spacing(7)})`, width: (theme) => `calc(100vw - ${theme.spacing(7)} )`, zIndex: 20, backgroundColor: "white" });
    return (_jsx(Fade, Object.assign({ in: isActive }, { children: _jsx(Box, Object.assign({ id: "msa-entrypoint", sx: dimensions }, { children: _jsx(Documentation, { pyodide: pyodide, desktopSections: desktopSections }) })) })));
}
