var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Grid } from "@mui/material";
import { Panels } from "../GenericViewerState/SharedState";
import { NAPTableStore } from "../StateStores/tables.store";
import { DataTable } from "./DataTable";
import { focusOnSystem } from "./utils";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { updateStateTreeVisibilityFromCellRefs } from "../3dViewer/stateTree";
import { clearHighlight, highlightStructure, } from "../utils/HighlightStructure";
import { DatavizControlOptions } from "../DataViz";
import { getToggleVizColnames, rowIsActive } from "./findActiveRows";
import { tableOnClickAction } from "./tableOnClickAction";
import { SelectTable } from "./SelectTable";
import { useLocation } from "wouter";
export function CSVtables({ vss, molstar, pyodide, NAPGenericStore, skipButtons, fileSelectorAsTabs, }) {
    var _a;
    const { tables, del_table, activeTableIdx, set_activeTableIdx } = NAPTableStore();
    const [activePanels, setActivePanels, setHighligtedTableSystemRef, visibleSystemsFiles,] = NAPGenericStore((state) => [
        state._activePanels,
        state.setActivePanels,
        state.setHighligtedTableSystemRef,
        state.visibleSystemsFiles,
    ], shallow);
    useEffect(() => {
        const _cb = (e) => {
            setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
        };
        window.addEventListener("PM-Loaded-Table", _cb);
        return () => {
            window.removeEventListener("PM-Loaded-Table", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const closePanel = () => setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    const skipDelete = skipButtons
        ? skipButtons.includes(DatavizControlOptions.delete)
        : false;
    const onDelete = skipDelete
        ? undefined
        : () => {
            del_table(activeTableIdx, closePanel);
        };
    const activeTable = tables[activeTableIdx];
    const rowActions = (_a = activeTable === null || activeTable === void 0 ? void 0 : activeTable.options) === null || _a === void 0 ? void 0 : _a.onRowClick;
    const [loadingDCFile, setLoadingDCFile] = NAPGenericStore((state) => [state.loadingDCFile, state.setLoadingDCFile], shallow);
    const toggleVizColnames = getToggleVizColnames(rowActions, activeTable);
    const _rowIsActive = (row) => {
        if (toggleVizColnames.length === 0)
            return false;
        return rowIsActive(row, toggleVizColnames, visibleSystemsFiles, activeTable.localBasePath, !!activeTable.DCpath);
    };
    const [, setLocation] = useLocation();
    const openSystemsPanel = () => {
        const activePanels = NAPGenericStore.getState()._activePanels;
        NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { statetree: true, apps: false }));
        setLocation("/");
    };
    const onRowClick = (row, rowIsActive) => __awaiter(this, void 0, void 0, function* () {
        let cellRefs;
        let visibility = true;
        if (rowActions) {
            if (loadingDCFile)
                return;
            setLoadingDCFile(true);
            ({ cellRefs, visibility } = yield tableOnClickAction(vss, molstar, pyodide, rowActions, row, activeTableIdx, openSystemsPanel, activeTable.DCpath, activeTable.localBasePath, rowIsActive));
            setLoadingDCFile(false);
        }
        else {
            const rowCellRefs = row.original.cellRefs;
            if (rowCellRefs && rowCellRefs.length > 0)
                cellRefs = [rowCellRefs];
        }
        if (!cellRefs || cellRefs.length === 0)
            return;
        const systems = vss.getState().loaded_structures;
        cellRefs.forEach((systCellRefs) => {
            setVisibility(molstar, systCellRefs, visibility);
            updateStateTreeVisibilityFromCellRefs(vss, systCellRefs, visibility);
            if (visibility)
                focusOnSystem(molstar, systCellRefs, systems);
        });
    });
    const onMouseEnter = (row) => {
        const cellRefs = row.original.cellRefs;
        if (!cellRefs)
            return;
        highlightStructure(molstar, cellRefs);
        setHighligtedTableSystemRef(cellRefs);
    };
    const onMouseLeave = () => {
        clearHighlight(molstar);
        setHighligtedTableSystemRef([]);
    };
    const numTables = tables.filter((t) => !t.archived).length;
    const isWaiting = rowActions && loadingDCFile;
    const rowsAreClickable = (rowActions && rowActions.length > 0) ||
        (activeTable === null || activeTable === void 0 ? void 0 : activeTable.columns.some((col) => typeof col.Header === "string" && col.Header.toLowerCase() === "path"));
    return numTables > 0 ? (_jsxs(_Fragment, { children: [numTables > 1 && (_jsx(Grid, Object.assign({ item: true, xs: 12, sx: { order: 2 } }, { children: _jsx(SelectTable, { handleChange: set_activeTableIdx, activeTableIdx: activeTableIdx, tables: tables, fileSelectorAsTabs: fileSelectorAsTabs }) }))), _jsx(DataTable, { tableID: tables[activeTableIdx].tableID, columns: tables[activeTableIdx].columns, data: tables[activeTableIdx].rows, options: tables[activeTableIdx].options, svgColumns: tables[activeTableIdx].svgColumns, onDelete: onDelete, onRowClick: onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, isWaiting: isWaiting, rowsAreClickable: rowsAreClickable, rowIsActive: _rowIsActive, visibleSystemsFiles: visibleSystemsFiles, activeTableIdx: activeTableIdx })] })) : (_jsx(_Fragment, { children: "No table data available!" }));
}
