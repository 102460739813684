// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { updateMoleculeReps } from "./updateMoleculeReps";
import { removeSystem } from "../3dViewer/Controls/utils";
import { findSystemByKey } from "../3dViewer/stateTree";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
export function setupPyodideContext(vss, molstar, pyodide, closeStateTreePanel, removeVisibleSystemsFiles) {
    return __awaiter(this, void 0, void 0, function* () {
        // Define get_system
        const update_pyodide_systemtree = () => {
            const updatedSystemsTree = vss.getState().loaded_structures;
            pyodide.RunPythonAsync({
                context: { updatedSystemsTree },
                script: `
      from js import updatedSystemsTree
      systems_tree = updatedSystemsTree.to_py()
      `,
            });
        };
        const check_system_change = () => __awaiter(this, void 0, void 0, function* () {
            pyodide
                .RunPythonAsync({
                context: {},
                script: `
      molecule_updates = []
      try:
        molecule_updates = monitor.is_changed(systems)
      except NameError:
        pass
      molecule_updates
      `,
            })
                .then((updates) => {
                const changedMolecules = updates[0];
                const emptyMolecules = updates[1];
                changedMolecules.forEach((moleculeID) => {
                    updateMoleculeReps(vss, molstar, pyodide, moleculeID);
                });
                showDeletionNotification(emptyMolecules);
                emptyMolecules.forEach((moleculeID) => {
                    const loaded_structures = vss.getState().loaded_structures;
                    const system = findSystemByKey(loaded_structures, "moleculeID", moleculeID);
                    const cellRefs = system.cellRef ? system.cellRef : [""];
                    // make a wrapper that only receives the ID
                    removeSystem(molstar, pyodide, vss, cellRefs, system.stateTreePosition);
                    if (loaded_structures.length === 0)
                        closeStateTreePanel();
                    if (removeVisibleSystemsFiles && system.files) {
                        removeVisibleSystemsFiles(system.files);
                    }
                });
            });
        });
        // Periodically check for updates in molkit molecules
        setInterval(() => {
            update_pyodide_systemtree();
            check_system_change();
        }, 300);
    });
}
const showDeletionNotification = (emptyMolecules) => {
    const msg1 = `Molecule deleted as empty after filtering.`;
    const msg2 = `Removed ${emptyMolecules.length} empty molecules after filtering.`;
    if (emptyMolecules.length === 1) {
        dispatchNotificationEvent({
            type: "warning",
            message: msg1,
        });
    }
    if (emptyMolecules.length > 1) {
        dispatchNotificationEvent({
            type: "warning",
            message: msg2,
        });
    }
};
