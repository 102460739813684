var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { memo, useEffect, useMemo, useState } from "react";
import { diff } from "deep-object-diff";
import { shallow } from "zustand/shallow";
import { Paper, Box, Grid, Collapse } from "@mui/material";
import { DeleteButton, TreeNodeVisibilityButton, FileType, ActionIconButton, TreeNodeToggleOptionsButton, IsLoadingGroup, } from "..";
import BrushIcon from "@mui/icons-material/Brush";
import { VolumeIsoControls } from "./VolumeIsoControls/VolumeIsoControls";
import { CustomAccordion } from "./SystemGroups/CustomAccordion";
import { setVisibility, setVisibilityForSDF } from "./VisibilityToggle";
import { removeSystem } from "./Controls/utils";
import { updateStateTreeVisibility, getSystemStateVisibility, selectSystemByIndexArray, } from "./stateTree";
import { cameraFocus } from "./Controls/CameraControls/cameraFocus";
import { EntryDisplayControls } from "./Controls/EntryDisplayControls";
import { CameraFocusButton } from "./Controls/CameraControls/FocusButton";
import { setStateTreeVisibleElementIDs } from "./stateTree";
import { TrajectoryFrameControls } from "./TrajectoryControls/TrajectoryFrameControls";
import { SystemCheckbox } from "./Controls/Groups/CreateGroupCheckbox";
import { SystemRadioButton } from "./SystemRadioButtons";
import { Panels } from "../GenericViewerState/SharedState";
import { dispatchSystemVisibilityChange } from "./DispatchEvents";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getTrajectoryRef } from "./TrajectoryControls/utils";
import CircularProgress from "@mui/material/CircularProgress";
/** Diff function that tells the component when to re-render. */
export function propsComparison(prevProps, nextProps) {
    const prevSystems = prevProps.vss.getState().loaded_structures;
    const nextSystems = nextProps.vss.getState().loaded_structures;
    const _diff = diff(prevSystems, nextSystems);
    if (_diff)
        return false;
    return true;
}
export const MolSystemsPanel = memo(MolSystemsPanelMEMO, propsComparison);
function MolSystemsPanelMEMO({ vss, molstar, pyodide, positionInTree = [], onCheckSystem, checkedSystems, areSystemsCheckboxActive, NAPGenericStore, simplified, }) {
    const systems = vss.getState().loaded_structures;
    if (!systems)
        return _jsx("div", {});
    let _systems;
    if (positionInTree.length === 0) {
        _systems = systems;
    }
    else {
        const groupSystem = selectSystemByIndexArray(positionInTree, systems);
        _systems = groupSystem.children ? groupSystem.children : [];
    }
    return (_jsx(_Fragment, { children: _systems.map(function (s, idx) {
            let node;
            s.stateTreePosition = [...positionInTree, idx];
            const isGroup = s.type === FileType.group;
            if (s.archived)
                return _jsx(_Fragment, {});
            if (isGroup) {
                node = (_jsx(CustomAccordion, Object.assign({ vss: vss, name: s.name, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, NAPGenericStore: NAPGenericStore, simplified: simplified, loading: !!s.isLoading, disabled: s.isLoading === IsLoadingGroup.all }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", spacing: 1, sx: { pt: 1 } }, { children: _jsx(MolSystemsPanelMEMO, { vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, onCheckSystem: onCheckSystem, checkedSystems: checkedSystems, areSystemsCheckboxActive: areSystemsCheckboxActive, NAPGenericStore: NAPGenericStore, simplified: simplified }) })) }), s.name));
            }
            else {
                node = (_jsx(SystemOptions, { system: s, vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: s.stateTreePosition, NAPGenericStore: NAPGenericStore, simplified: simplified }, s.name));
            }
            return (_jsxs(Grid, Object.assign({ item: true, container: true, alignItems: "flex-start" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: "auto", id: `checkbox-${s.stateTreePosition.join("-")}` }, { children: [isGroup ? null : (_jsx(SystemRadioButton, { vss: vss, pyodide: pyodide, boxKey: s.stateTreePosition.join("-"), name: s.name, NAPGenericStore: NAPGenericStore })), _jsx(SystemCheckbox, { boxKey: s.stateTreePosition.join("-"), onCheckSystem: onCheckSystem, checkedSystems: checkedSystems, areSystemsCheckboxActive: areSystemsCheckboxActive })] })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: node }))] }), `grid-${s.stateTreePosition.join("-")}`));
        }) }));
}
function SystemControlButtonsSimplified({ systemName, onDetailsClick, onVisibilityClick, onFocusClick, isDetailsOpen, visibility = true, isHighlighted, toggleSpecificOptions, hasSpecificOptions, hideSpecificOptions, isLoading, }) {
    return (_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(TreeNodeToggleOptionsButton, { disabled: !visibility || !!isLoading, onClick: toggleSpecificOptions, name: systemName, active: visibility && !isLoading, isHighlighted: isHighlighted, hideSpecificOptions: hideSpecificOptions, notClickable: !hasSpecificOptions }) })), isLoading ? (_jsx(CircularProgress, { size: "20px", sx: { m: "5px", color: "#757575" } })) : (_jsxs(_Fragment, { children: [visibility && (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onDetailsClick, tooltipMsg: "Representations", disabled: !visibility, active: isDetailsOpen, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: _jsx(BrushIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CameraFocusButton, { tooltipMsg: "Focus System", focusCb: onFocusClick, disabled: !visibility }) }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onVisibilityClick, tooltipMsg: visibility ? "Hide" : "Show", backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: visibility ? _jsx(VisibilityIcon, {}) : _jsx(VisibilityOffIcon, {}) })) }))] }))] })));
}
function SystemControlButtons({ molstar, systemName, onDetailsClick, onDeleteClick, onVisibilityClick, onFocusClick, isDetailsOpen, visibility = true, isHighlighted, }) {
    return (_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(TreeNodeVisibilityButton, { onClick: onVisibilityClick, name: systemName, active: visibility, isHighlighted: isHighlighted }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CameraFocusButton, { tooltipMsg: "Focus System", focusCb: onFocusClick, disabled: !visibility }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: onDetailsClick, tooltipMsg: "Representations", disabled: !visibility, active: isDetailsOpen, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40" }, { children: _jsx(BrushIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(DeleteButton, { onClick: () => onDeleteClick(molstar), tooltipMsg: "Delete System", placement: "right", confirmationMsg: `Are you sure you want to delete system "${systemName}" ?` }) }))] })));
}
function VolumeOptions({ vss, molstar, system, positionInTree, visibility, }) {
    const volParams = system.volumeRepresentation;
    return (_jsx(VolumeIsoControls, { vss: vss, molstar: molstar, cellRef: system.cellRef ? system.cellRef[0] : "", visibility: visibility, maxIsoValue: volParams["maxIsoValue"], minIsoValue: volParams["minIsoValue"], positionInTree: positionInTree }));
}
function SDFandPdbBatchOptions({ vss, molstar, visibility = true, positionInTree, cellRefs, fileType, }) {
    const [loaded_structures] = vss((state) => [state.loaded_structures]);
    const activeSystem = selectSystemByIndexArray(positionInTree, loaded_structures);
    const predefinedIndexSelection = activeSystem.visibleElementIDs;
    function onVisibilityChange(activeTextFieldVal) {
        if (!visibility)
            return;
        for (const rep of activeSystem.representations) {
            if (!rep.visibility || !rep.refs)
                continue;
            setVisibilityForSDF(molstar, activeTextFieldVal, rep.refs);
        }
    }
    function onActiveElementsUpdate(activeTextFieldVal) {
        onVisibilityChange(activeTextFieldVal);
        setStateTreeVisibleElementIDs(vss, positionInTree, activeTextFieldVal);
    }
    return (_jsx(_Fragment, { children: cellRefs.length > 1 ? (_jsx(EntryDisplayControls, { structCellRefs: cellRefs, predefinedIndexSelection: predefinedIndexSelection, fileType: fileType, visibility: visibility, onActiveElementsUpdate: onActiveElementsUpdate, onVisibilityChange: onVisibilityChange })) : (_jsx(_Fragment, {})) }));
}
function SystemOptions({ vss, pyodide, molstar, system, positionInTree, NAPGenericStore, simplified, }) {
    var _a, _b;
    const [loaded_structures] = vss((state) => [state.loaded_structures]);
    const [activeSystemPosition, setActiveSystemPosition, setOpenBottomPanel, activePanels, setActivePanels, highligtedTableSystemRef, addVisibleSystemsFiles, removeVisibleSystemsFiles,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state.setActiveSystemPosition,
        state.setOpenBottomPanel,
        state._activePanels,
        state.setActivePanels,
        state.highligtedTableSystemRef,
        state.addVisibleSystemsFiles,
        state.removeVisibleSystemsFiles,
    ], shallow);
    const visibility = useMemo(() => getSystemStateVisibility(vss, positionInTree), [positionInTree, vss]);
    useEffect(() => {
        const systFiles = system.files;
        if (!systFiles || systFiles.length == 0)
            return;
        if (visibility)
            addVisibleSystemsFiles(systFiles);
        else
            removeVisibleSystemsFiles(systFiles);
    }, [visibility]);
    const fileType = system.type;
    const isHighlighted = system.cellRef
        ? system.cellRef.some((ref) => highligtedTableSystemRef.includes(ref))
        : false;
    const cellRefs = system.cellRef ? system.cellRef : [""];
    function onDetailsClick() {
        if (activeSystemPosition &&
            JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree)) {
            setOpenBottomPanel(false);
            setActiveSystemPosition(undefined);
        }
        else {
            setOpenBottomPanel(true);
            setActiveSystemPosition(positionInTree);
        }
    }
    function onDeleteClick(molstar) {
        return __awaiter(this, void 0, void 0, function* () {
            yield setActiveSystemPosition(undefined);
            setOpenBottomPanel(false);
            removeSystem(molstar, pyodide, vss, cellRefs, positionInTree);
            if (loaded_structures.length === 0)
                setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false }));
            if (system.files)
                removeVisibleSystemsFiles(system.files);
        });
    }
    function onVisibilityClick() {
        const visibility = getSystemStateVisibility(vss, positionInTree); // This was not necessary before
        setVisibility(molstar, cellRefs, !visibility);
        updateStateTreeVisibility(vss, positionInTree, !visibility);
        if (visibility &&
            activeSystemPosition &&
            JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree)) {
            //This closes the system representation panel if system is made not visible
            setActiveSystemPosition(undefined);
            setOpenBottomPanel(false);
        }
        dispatchSystemVisibilityChange({ system: system, visibility: !visibility });
    }
    const onFocusClick = () => cameraFocus(molstar, cellRefs);
    const isSystemRepsOpen = JSON.stringify(activeSystemPosition) === JSON.stringify(positionInTree);
    const multiSelection = (fileType === FileType.sdf || fileType === FileType.coordinates_group) &&
        cellRefs.length > 1;
    const isovalueSelection = fileType === FileType.volume;
    const systemIsTrajectory = fileType === FileType.trajectory;
    let trajectoryRef;
    if (systemIsTrajectory) {
        if (system.cellRef) {
            trajectoryRef = getTrajectoryRef(molstar, system.cellRef);
        }
    }
    const hasSpecificOptions = multiSelection || isovalueSelection || trajectoryRef;
    const [specificOptionsCollapsed, setSpecificOptionsCollapsed] = useState(false);
    useEffect(() => {
        if (simplified)
            setSpecificOptionsCollapsed(true);
    }, []);
    const hideFormatSpecificOptions = specificOptionsCollapsed || !visibility;
    return (_jsx(Box, Object.assign({ sx: { width: "100%", px: 1, pl: "35px" } }, { children: _jsxs(Paper, { children: [simplified ? (_jsx(SystemControlButtonsSimplified, { molstar: molstar, isDetailsOpen: isSystemRepsOpen, onDetailsClick: onDetailsClick, onDeleteClick: () => onDeleteClick(molstar), onVisibilityClick: onVisibilityClick, onFocusClick: onFocusClick, visibility: visibility, systemName: system.name, isHighlighted: isHighlighted, toggleSpecificOptions: () => {
                        setSpecificOptionsCollapsed((prev) => !prev);
                    }, hideSpecificOptions: hideFormatSpecificOptions, hasSpecificOptions: hasSpecificOptions, isLoading: system.isLoading })) : (_jsx(SystemControlButtons, { molstar: molstar, isDetailsOpen: isSystemRepsOpen, onDetailsClick: onDetailsClick, onDeleteClick: () => onDeleteClick(molstar), onVisibilityClick: onVisibilityClick, onFocusClick: onFocusClick, visibility: visibility, systemName: system.name, isHighlighted: isHighlighted })), _jsx(Collapse, Object.assign({ in: !hideFormatSpecificOptions }, { children: multiSelection ? (_jsx(SDFandPdbBatchOptions, { vss: vss, molstar: molstar, visibility: visibility, positionInTree: positionInTree, cellRefs: cellRefs, fileType: fileType })) : isovalueSelection ? (_jsx(VolumeOptions, { vss: vss, molstar: molstar, system: system, positionInTree: positionInTree, visibility: visibility })) : trajectoryRef ? (_jsx(TrajectoryFrameControls, { vss: vss, molstar: molstar, trajCellRef: trajectoryRef, visibility: visibility, positionInTree: positionInTree, defaultFrame: (_a = system.trajectoryParameters) === null || _a === void 0 ? void 0 : _a.frame, defaultStep: (_b = system.trajectoryParameters) === null || _b === void 0 ? void 0 : _b.step })) : null }))] }) })));
}
