import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box } from "@mui/material";
import { NAPPlotStore, NAPTableStore } from "../../StateStores";
import { calculatePlotHeight } from "./utils";
export function ImagePlot({ extension, content, }) {
    const plots = NAPPlotStore((state) => state.plots);
    const tables = NAPTableStore((state) => state.tables);
    const height = calculatePlotHeight(tables, plots);
    return (_jsx(Box, Object.assign({ justifyContent: "center", sx: { overflowY: "auto", height: height } }, { children: _jsx("img", { style: { width: "100%", height: "auto" }, alt: "", src: extension === "png"
                ? `data:image/png;base64,${content}`
                : `data:image/svg+xml;utf8,${encodeURIComponent(content)}` }) })));
}
