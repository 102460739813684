import { docsViewerSections } from "./viewerConstants";
import { docsAppsSections } from "./applicationsConstants";
import { docsPolicies } from "./policiesConstants";
export const docsSections = [
    {
        name: "Viewer",
        subSections: docsViewerSections,
    },
    { name: "Policies", subSections: docsPolicies },
    {
        name: "Applications",
        subSections: docsAppsSections,
        //   subSections: appList.filter((e) =>
        //     currentApps.includes(e.name)
        //   ) as AppManifest[],
    },
];
