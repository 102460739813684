import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DCBrowserStore } from "../../DatacenterBrowser";
import { shallow } from "zustand/shallow";
function containsInvalidDirChar(str) {
    return /[^A-Za-z0-9\-._~% ]/.test(str);
}
export function NewProjectDialog({ isDialogOpen, closeDialog, addProjectCallback, }) {
    const [sessionProjects] = DCBrowserStore((state) => [state.sessionProjects], shallow);
    const [fieldContent, setFieldContent] = useState({ name: "", error: "" });
    const handleInputChange = (name) => {
        let error = "";
        const maxLen = 50;
        if (name.length > maxLen)
            error = `Name must be ${maxLen} characters or fewer.`;
        else if (containsInvalidDirChar(name))
            error = "Invalid character.";
        else
            error = "";
        setFieldContent({
            name,
            error,
        });
    };
    const handleAddProject = () => {
        const projectName = fieldContent.name;
        const cleanProjectName = projectName.replace(/ /g, "_").toLowerCase();
        // check if project already exists
        const projectExists = sessionProjects === null || sessionProjects === void 0 ? void 0 : sessionProjects.some((existingProject) => existingProject.toLowerCase() === cleanProjectName);
        if (projectExists) {
            setFieldContent((old) => {
                return Object.assign(Object.assign({}, old), { error: "Project already exists" });
            });
            return;
        }
        closeDialog();
        // reset dataset folders, reset folder position, set project, update projects list
        addProjectCallback(cleanProjectName);
        setFieldContent({ name: "", error: "" });
    };
    const disableCreate = !fieldContent.name || !!fieldContent.error;
    return (_jsxs(Dialog, Object.assign({ open: isDialogOpen, onClose: closeDialog, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ textAlign: "center" }, { children: ["New project", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: closeDialog, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, Object.assign({ sx: { py: 0 } }, { children: _jsx(TextField, { error: !!fieldContent.error, helperText: fieldContent.error, sx: { my: 1 }, label: "Project Name", variant: "outlined", value: fieldContent.name, size: "small", onChange: (e) => handleInputChange(e.target.value), autoFocus: true, onKeyDown: (e) => {
                        if (e.key === "Enter" && !disableCreate) {
                            handleAddProject();
                        }
                    } }) })), _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", disabled: disableCreate, onClick: () => {
                        handleAddProject();
                    } }, { children: "Create" })) }))] })));
}
