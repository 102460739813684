import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Typography, Paper } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { highlightStructure, clearHighlight, } from "../..//utils/HighlightStructure";
import { repTypeToLabel, repColorToLabel } from "./representationParamOptions";
import { shallow } from "zustand/shallow";
import { DeleteButton, selectSystemByIndexArray, } from "../..";
import { cameraFocus } from "../Controls/CameraControls/cameraFocus";
import { CameraFocusButton } from "../Controls/CameraControls/FocusButton";
import { AddButton } from "../Controls/BasicControls";
import { removeRepresentation } from "../Controls/utils";
export function RepresentationListWithActions({ vss, molstar, selectedRepID, setSelectedRepID, handleToggleRepVisibility, showAddRepPanel, setShowAddRepPanel, NAPGenericStore, }) {
    var _a, _b;
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(loaded_structures, activeSystemPosition)
        : undefined;
    const numReps = ((_a = activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const selectedRep = numReps && (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem.representations[selectedRepID]
        : undefined;
    const selectedRepRefs = (_b = selectedRep === null || selectedRep === void 0 ? void 0 : selectedRep.refs) === null || _b === void 0 ? void 0 : _b.map((r) => r.repRef);
    const handleRemoveRepClick = () => {
        if (!selectedRepRefs)
            return;
        removeRepresentation(vss, molstar, selectedRepRefs, activeSystemPosition, selectedRepID);
        setSelectedRepID(0);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UpperSection, { children: _jsx(Paper, Object.assign({ variant: "outlined", sx: { p: "0.25rem", width: "100%" } }, { children: _jsx(Grid, Object.assign({ container: true, direction: "column", wrap: "nowrap", sx: {
                            width: "100%",
                            maxHeight: "7rem",
                            overflowY: "auto",
                            pt: 1,
                            pl: 1,
                        } }, { children: activeSystem &&
                            activeSystem.representations &&
                            activeSystem.representations.map((repParams, idx) => (_jsx(SystemRepresentationListRow, { molstar: molstar, repParams: repParams, handleSingleClick: () => setSelectedRepID(idx), handleDoubleClick: handleToggleRepVisibility, isActive: idx === selectedRepID, disabled: showAddRepPanel ? true : false, idx: idx }, `representation-row-${idx}`))) })) })) }), _jsxs(LowerSection, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(AddButton, { onClick: () => setShowAddRepPanel(true), tooltipMsg: "New Representation" }) })), numReps > 0 && selectedRepRefs && (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(DeleteButton, { onClick: handleRemoveRepClick, tooltipMsg: "Remove Representation", confirmationMsg: "Are you sure you want to delete this representation?" }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(CameraFocusButton, { focusCb: () => cameraFocus(molstar, selectedRepRefs), mouseOverCb: () => highlightStructure(molstar, selectedRepRefs), mouseOutCb: () => clearHighlight(molstar), tooltipMsg: "Focus Representation" }) }))] }))] })] }));
}
export function SystemRepresentationListRow({ molstar, repParams, handleSingleClick, handleDoubleClick, isActive, disabled, idx, }) {
    const visible = repParams.visibility === undefined ? true : repParams.visibility;
    const repRef = repParams.refs && repParams.refs[0] ? repParams.refs[0].repRef : undefined;
    return (_jsxs(Grid, Object.assign({ item: true, container: true, spacing: 1, sx: { cursor: "pointer", backgroundColor: isActive ? "#EBEBEB" : "auto" }, onClick: (e) => {
            if (disabled || repRef === undefined)
                return;
            highlightStructure(molstar, [repRef]);
            e.detail > 1 ? handleDoubleClick() : handleSingleClick();
        } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 3, zeroMinWidth: true }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repTypeToLabel[repParams.type] }) })), _jsx(Grid, Object.assign({ item: true, xs: 3, zeroMinWidth: true }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repColorToLabel[repParams.color] }) })), _jsx(Grid, Object.assign({ item: true, xs: 5, zeroMinWidth: true }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repParams.selection }) })), _jsx(Grid, Object.assign({ item: true, xs: 1 }, { children: !visible ? (_jsx(VisibilityOffIcon, { fontSize: "small", sx: {
                        color: "#BDBDBD",
                    } })) : (_jsx(VisibilityIcon, { fontSize: "small", sx: {
                        color: disabled ? "#BDBDBD" : "auto",
                    } })) }))] }), `repr-entry-${idx}`));
}
const UpperSection = ({ children }) => (_jsx(Grid, Object.assign({ item: true, container: true, sx: { width: "100%" } }, { children: children })));
const LowerSection = ({ children }) => (_jsx(Grid, Object.assign({ item: true, container: true, spacing: 1, direction: "row" }, { children: children })));
function RepresentationRowText({ disabled, content, }) {
    return (_jsx(Typography, Object.assign({ noWrap: true, sx: {
            textTransform: "none",
            textAlign: "left",
            width: "100%",
            fontSize: "0.8rem",
            userSelect: "none",
            color: disabled ? "#BDBDBD" : "auto",
        } }, { children: content })));
}
