export function applyActionOnPlotNodes(action, chartHandler, nodeIDs) {
    chartHandler.dispatchAction({
        type: action,
        dataIndex: nodeIDs,
    });
}
export function calculatePlotHeight(tables, plots) {
    const numTables = tables.filter((t) => !t.archived).length;
    const numPlots = plots.filter((p) => !p.archived).length;
    const plotSel = numPlots > 1;
    const tabs = numTables > 0;
    return `calc(100vh - 54px${plotSel ? " - 56px" : ""}${tabs ? " - 56px" : ""}${plotSel || tabs ? " - 34px" : ""})`;
}
