var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { dsInfoFromId } from "../../apps/results/utils";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../../NotificationSystem/utils";
import { readDCfile } from "../../apps/results/fetchDcData";
export function DownloadDatasetBtn({ currentFolderContent, currentFolderId, disabled, }) {
    const hasDCid = currentFolderContent.length > 0 &&
        /^dc:\/\/\d+$/.test(currentFolderContent[0].parentId);
    return hasDCid ? (_jsx(Button, Object.assign({ disabled: disabled, variant: "outlined", endIcon: _jsx(DownloadIcon, {}), sx: { m: 1 }, onClick: () => downloadJobResult(currentFolderId) }, { children: "Download" }))) : (_jsx(_Fragment, {}));
}
export function downloadJobResult(folderId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { ds_id, files } = dsInfoFromId(folderId);
        if (!ds_id)
            return;
        dispatchControlledNotification({
            idx: folderId,
            type: "info",
            message: `Reading job output...`,
        });
        const response = yield readDCfile(ds_id, files);
        if (!response || typeof response === "string")
            return;
        const u8a = new Uint8Array(response);
        const blob = new Blob([u8a], {
            type: "application/x-gzip",
        });
        const url = URL.createObjectURL(blob);
        dispatchDeleteControlledNotification({ idx: folderId });
        const anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = `${ds_id}.tar.gz`;
        anchorElement.click();
        window.URL.revokeObjectURL(url);
    });
}
