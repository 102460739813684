var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { dsInfoFromId, getFilesType } from "./utils";
import { executeWithTokenRefresh } from "../../Authentication";
export function getRawFileContent(dsfile_id, pyodide, isBin) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let vfs_path = yield pyodide.RunPythonAsync({
                context: { dsfile_id },
                script: `
      from js import dsfile_id
      find_file_content(dsfile_id)
      `,
            });
            let contentData;
            const { ds_id, files } = dsInfoFromId(dsfile_id);
            if (!ds_id)
                return;
            if (vfs_path) {
                let response = yield pyodide.RunPythonAsync({
                    context: { dsfile_id, vfs_path },
                    script: `
        from js import dsfile_id, vfs_path
        extract_file_content(vfs_path, dsfile_id)
        `,
                });
                if (!response)
                    return;
                contentData = isBin ? response : JSON.parse(response).data;
            }
            else {
                contentData = yield readDCfile(ds_id, files, isBin);
            }
            return contentData;
        }
        catch (e) {
            console.log(e);
        }
    });
}
export function downloadDatasetFiles(ds_id, pyodide, files_li, downloadPath) {
    return __awaiter(this, void 0, void 0, function* () {
        const dirpath = downloadPath !== undefined ? downloadPath : `/tmp/${ds_id}`;
        const alreadyExists = yield pyodide.RunPythonAsync({
            context: { dirpath },
            script: `
      from js import dirpath
      os.path.exists(dirpath)
      `,
        });
        if (!alreadyExists) {
            yield pyodide.RunPythonAsync({
                context: { dirpath },
                script: `
        from js import dirpath
        from pathlib import Path
        Path(dirpath).mkdir(parents=True)
        `,
            });
            const tarfileContent = yield readDCfile(ds_id, files_li);
            if (!tarfileContent || typeof tarfileContent === "string")
                return;
            const tarpath = `${dirpath}/tmp.tar.gz`;
            const contentArray = new Uint8Array(tarfileContent);
            pyodide.FS.writeFile(tarpath, contentArray, "binary");
            yield pyodide.RunPythonAsync({
                context: { tarpath },
                script: `
        from js import tarpath
        extracted_dir = os.path.dirname(tarpath)
        with tarfile.open(tarpath, 'r:gz') as tar:
            tar.extractall(extracted_dir)
        os.remove(tarpath)
        `,
            });
        }
    });
}
export function readDCfile(ds_id, files, isBin) {
    return __awaiter(this, void 0, void 0, function* () {
        const { isDir, zipExt } = getFilesType(files);
        const headers = {
            tar: isDir,
        };
        if (files && files.length > 0) {
            headers["files"] = files.join(",");
        }
        if (zipExt) {
            headers["extension"] = "zip";
        }
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/datacenter/${ds_id}`, {
            method: "GET",
            //@ts-ignore
            headers: headers,
        }));
        if (!response.success) {
            console.error("Error fecthing dataset id");
            return;
        }
        if (isDir || isBin || zipExt) {
            return yield response.result.arrayBuffer();
        }
        else {
            return yield response.result.text();
        }
    });
}
export function getDatasets(params) {
    return __awaiter(this, void 0, void 0, function* () {
        const headers = {
            userOnly: (!!params.useronly).toString(),
            datasetId: params.datasetid ? params.datasetid.toString() : "",
            filePath: params.remotepath ? params.remotepath : "",
            public: params.public ? params.public.toString() : "",
            startsWith: params.startswith ? params.startswith : "",
            tags: params.tags ? params.tags.join(",") : "",
            taggedOnly: (!!params.taggedonly).toString(),
            completedOnly: (!!params.completedonly).toString(),
            group: params.group ? params.group : "",
            filelist: params.filelist ? params.filelist.toString() : "",
            execID: params.execid ? params.execid : "",
        };
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/datacenter`, {
            method: "GET",
            headers: headers,
        }));
        if (!response.success) {
            console.error(response.error);
            return;
        }
        const datasets = yield response.result.json();
        return datasets;
    });
}
