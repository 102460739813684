import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { representationColorOptions } from "./representationParamOptions";
import { Grid } from "@mui/material";
import { SelectionField } from "./SelectRepresentationParam";
import { SelectColor } from "../Controls/SelectColor";
export function RepresentationColorSelector({ onSchemeSelection, onColorPickerUpdate, currColorScheme, currColorVal, }) {
    const [reprColorScheme, setReprColorScheme] = useState(currColorScheme);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    useEffect(() => {
        setReprColorScheme(currColorScheme);
        setDisplayColorPicker(currColorScheme === "uniform");
    }, [currColorScheme]);
    useEffect(() => {
        onSchemeSelection(reprColorScheme);
        setDisplayColorPicker(reprColorScheme === "uniform");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reprColorScheme]);
    const handleSelection = (e) => {
        const newVal = e.target.value;
        setReprColorScheme(newVal);
    };
    const menuLabel = "Color Scheme";
    const options = representationColorOptions;
    return (_jsxs(Grid, Object.assign({ container: true, alignItems: "center", columnSpacing: 2, direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(SelectionField, { currValue: reprColorScheme, menuLabel: menuLabel, options: options, callback: handleSelection }) })), displayColorPicker ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { pt: 2 } }, { children: _jsx(SelectColor, { activeColor: currColorVal ? currColorVal : 13421772, callback: onColorPickerUpdate }) }))) : null] })));
}
