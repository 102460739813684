var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useState, useMemo } from "react";
import { setVisibility } from "../VisibilityToggle";
import { removeSystem } from "../Controls/utils";
import { Panels, } from "../../GenericViewerState/SharedState";
import { updateStateTreeVisibility, getSystemStateVisibility, getAllChildSystems, } from "../stateTree";
import { childCellRefFromIndexArray } from "./groupUtils";
import { SystemGroupAccordion } from "./SystemGroupAccordion";
import { SystemGroupAccordionSimplified } from "./SystemGroupAccordionSimplified";
function removeVisibleGroupFiles(positionInTree, removeVisibleSystemsFiles, vss) {
    const childSystems = getAllChildSystems(vss, positionInTree);
    childSystems.forEach((syst) => {
        if (syst.files) {
            removeVisibleSystemsFiles(syst.files);
        }
    });
}
export function CustomAccordion({ vss, molstar, pyodide, name, children, positionInTree, NAPGenericStore, simplified, disabled, loading, }) {
    const loaded_structures = vss.getState().loaded_structures;
    const [activeSystemPosition, setActiveSystemPosition, setOpenBottomPanel, activePanels, setActivePanels, removeVisibleSystemsFiles,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state.setActiveSystemPosition,
        state.setOpenBottomPanel,
        state._activePanels,
        state.setActivePanels,
        state.removeVisibleSystemsFiles,
    ], shallow);
    const groupVisibility = useMemo(() => getSystemStateVisibility(vss, positionInTree), [positionInTree, vss]);
    const childRefs = childCellRefFromIndexArray(vss, positionInTree);
    const [accordionIsExpanded, setAccordionIsExpanded] = useState(false);
    const toggleAccordion = () => {
        setAccordionIsExpanded((currentIsExpanded) => !currentIsExpanded);
    };
    const handleDeleteGroupClick = () => __awaiter(this, void 0, void 0, function* () {
        setActiveSystemPosition(undefined);
        setOpenBottomPanel(false);
        removeVisibleGroupFiles(positionInTree, removeVisibleSystemsFiles, vss);
        removeSystem(molstar, pyodide, vss, childRefs, positionInTree);
        if (loaded_structures.length === 0)
            setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false }));
    });
    function handleVisibilityClick() {
        setVisibility(molstar, childRefs, !groupVisibility);
        updateStateTreeVisibility(vss, positionInTree, !groupVisibility);
        if (groupVisibility &&
            activeSystemPosition &&
            JSON.stringify(activeSystemPosition.slice(0, positionInTree.length)) ===
                JSON.stringify(positionInTree)) {
            //This closes the system representation panel if is a child of the group made not visible
            setActiveSystemPosition(undefined);
            setOpenBottomPanel(false);
        }
    }
    return simplified ? (_jsx(SystemGroupAccordionSimplified, Object.assign({ accordionIsExpanded: accordionIsExpanded, toggleAccordion: toggleAccordion, handleVisibilityClick: handleVisibilityClick, groupVisibility: groupVisibility, handleDeleteGroupClick: handleDeleteGroupClick, name: name, disabled: disabled, loading: loading }, { children: children }))) : (_jsx(SystemGroupAccordion, Object.assign({ accordionIsExpanded: accordionIsExpanded, toggleAccordion: toggleAccordion, handleVisibilityClick: handleVisibilityClick, groupVisibility: groupVisibility, handleDeleteGroupClick: handleDeleteGroupClick, name: name }, { children: children })));
}
