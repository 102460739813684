import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useMemo } from "react";
import { Link } from "wouter";
import { Grid, MenuItem, TextField, InputAdornment, Box, ListItemIcon, Typography, } from "@mui/material";
import { Apps } from "../../GenericViewerState/SharedState";
import SearchIcon from "@mui/icons-material/Search";
import produce from "immer";
import { shallow } from "zustand/shallow";
import { FEPGraphStore } from "../../Plots/FEP/FEPGraph.store";
import { AppListStore } from "./appList.store";
import { capitalizeFirstLetter } from "../../FormGenerator/utils";
import { StyledChip } from "./StyledChip";
import { setKeywordColors } from "./utils";
import { StyledTooltipApps } from "./StyledTooltipApps";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline"; // import { FilterMultipleSelect } from "./FilterByKeyword";
export function ApplicationsMenu({ NAPGenericStore, }) {
    const { getAppLinks } = AppListStore((state) => ({
        getAppLinks: state.getAppLinks,
    }), shallow);
    const appList = getAppLinks();
    const [searchText, setSearchText] = useState("");
    const { displayedAppDict, keyColors } = useMemo(() => {
        var _a;
        (_a = appList[0]) === null || _a === void 0 ? void 0 : _a.groupEntries.forEach((app) => {
            if (app.name in forceKeywords && !app.meta_keywords)
                app.meta_keywords = forceKeywords[app.name];
        });
        const menuList = [...datacenterList, ...appList];
        const keyColors = setKeywordColors(menuList);
        const matchingApps = produce(menuList, (prev) => prev.forEach((section) => {
            /* 1. filter each section internally */
            const appSubset = section.groupEntries.filter((entry) => {
                const key = entry["label"] ? entry["label"] : entry.name;
                return key.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            });
            /* 2. use the subset */
            section.groupEntries = appSubset;
        }));
        return {
            displayedAppDict: matchingApps.filter((section) => section.groupEntries.length > 0),
            keyColors: keyColors,
        };
    }, [searchText, appList]);
    return (_jsx(Box, Object.assign({ sx: { mt: 1 } }, { children: _jsxs(_Fragment, { children: [_jsx(SearchBar, { searchText: searchText, setSearchText: setSearchText }), _jsx(AppMenuItems, { appGroups: displayedAppDict, keyColors: keyColors, NAPGenericStore: NAPGenericStore })] }) })));
}
function MenuChips({ keywords, keyColors, }) {
    return (_jsx(Box, Object.assign({ display: "flex", sx: {
            gap: 1,
            overflowX: "auto",
            "&::-webkit-scrollbar": {
                height: "6px", // Customize the height of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#bfbfbf",
                "&:hover": {
                    backgroundColor: "#a6a6a6", // Customize the scrollbar thumb color on hover
                },
            },
        } }, { children: keywords.map((key) => (_jsx(StyledChip, { small: true, variant: "outlined", label: capitalizeFirstLetter(key), colorCustom: keyColors ? keyColors[key] : undefined }, `menu-chip-${key.replace(" ", "_")}`))) })));
}
function AppMenuItem({ name, label, keywords /*icon*/, keyColors, NAPGenericStore, description, icon, }) {
    const setShowGraph = FEPGraphStore((state) => state.setShowGraph);
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const togglePlots = () => {
        const toggledPanel = produce(activePanels, (draft) => {
            draft.plots = !draft.plots;
        });
        setActivePanels(toggledPanel);
        setShowGraph(true);
    };
    if (name === Apps.fepGraph)
        return (_jsx(MenuItem, Object.assign({ onClick: togglePlots, sx: { paddingLeft: 4 } }, { children: _jsxs(Grid, Object.assign({ container: true, wrap: "nowrap", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: label ? label : capitalizeFirstLetter(name) })), keywords ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(MenuChips, { keywords: keywords, keyColors: keyColors }) }))) : null] })) })));
    return (_jsx(Link, Object.assign({ href: `/tools/${name}` }, { children: _jsx(StyledTooltipApps, Object.assign({ title: description ? description : "", placement: "right" }, { children: _jsx(MenuItem, Object.assign({ sx: { paddingLeft: 4, py: 1 } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "row", justifyContent: "flex-start", alignItems: "flex-start" }, { children: [icon ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ListItemIcon, { children: icon }) }))) : null, _jsxs(Grid, Object.assign({ item: true, xs: true, container: true, wrap: "nowrap", direction: "column" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: label ? label : capitalizeFirstLetter(name) })) })), keywords ? (_jsx(MenuChips, { keywords: keywords, keyColors: keyColors })) : null] }))] })) })) })) })));
}
function AppMenuItems({ appGroups, keyColors, NAPGenericStore, }) {
    const menuItems = [];
    appGroups.forEach((section, i) => {
        menuItems.push(_jsx(MenuItem, Object.assign({ sx: {
                color: "rgba(0, 0, 0, 0.6)",
                cursor: "default",
                backgroundColor: "#f2f2f2",
                "&:hover": { backgroundColor: "#f2f2f2" },
            }, disableRipple: true }, { children: _jsx(Typography, { children: section.groupName }) }), `menu-sect-${i}`));
        appGroups[i].groupEntries.forEach((entry, j) => {
            menuItems.push(_jsx(AppMenuItem, { name: entry.name, label: entry["label"], keywords: entry["meta_keywords"], keyColors: keyColors, NAPGenericStore: NAPGenericStore, description: entry.description, icon: entry.icon }, `menu-sect-${i}-${j}`));
        });
    });
    return _jsx(Box, Object.assign({ sx: { mb: 2 } }, { children: menuItems }));
}
function SearchBar({ searchText, setSearchText, }) {
    return (_jsx(TextField, { sx: { px: 1, pb: 1 }, size: "small", 
        // autoFocus
        placeholder: "Type to search...", fullWidth: true, InputProps: {
            startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchIcon, {}) }))),
        }, onChange: (e) => setSearchText(e.target.value), onKeyDown: (e) => {
            e.stopPropagation();
        } }));
}
const datacenterList = [
    {
        groupName: "Datacenter",
        groupEntries: [
            {
                name: "datacenter",
                label: "File browser",
                meta_keywords: ["data center"],
                icon: _jsx(FolderOpenIcon, { fontSize: "small", sx: { mb: "0.2rem" } }),
            },
            {
                name: "jobs",
                label: "Job tracking",
                meta_keywords: ["data center"],
                icon: _jsx(WorkOutlineIcon, { fontSize: "small", sx: { mb: "0.2rem" } }),
            },
        ],
    },
];
// const ATMapp = {
//   keywords: ["Data visualization"],
//   name: "fepGraph",
//   label: "ATM graph",
// };
//Until I figure out why can't I get AceProfiler keywords
const forceKeywords = {
    aceprofiler: ["Protein", "Binding site"],
};
