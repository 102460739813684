import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Tooltip } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { TableRowButtonAction } from "../utils";
import urlJoin from "url-join";
import { NAPGenericStore } from "../GenericViewerState";
function displayImageFromTable(cell, column, localBasePath) {
    const row = cell.row;
    const loadRowCol = Object.keys(row.original).find((rowCol) => rowCol.toLowerCase() === column.toLowerCase());
    if (!loadRowCol)
        return;
    const fileName = row.original[loadRowCol];
    let absPath;
    if (fileName.charAt(0) === "/") {
        absPath = fileName;
    }
    else {
        absPath = urlJoin(localBasePath, fileName);
    }
    NAPGenericStore.getState().setContentPanelLocalFilePath(absPath);
    NAPGenericStore.getState().setOpenFileContentPanel(true);
}
export function TableButtonCell({ cell, action, column, tooltip, localBasePath, }) {
    const handleClick = (event) => {
        event.stopPropagation();
        switch (action) {
            case TableRowButtonAction.displayImage:
                if (!localBasePath)
                    return;
                displayImageFromTable(cell, column, localBasePath);
                break;
            default:
                break;
        }
    };
    const button = (_jsx(IconButton, Object.assign({ sx: {
            top: "-5px",
            left: "-15px",
        }, size: "small", "aria-label": "delete", onClick: handleClick }, { children: _jsx(BarChartIcon, { fontSize: "small" }) })));
    return tooltip ? (_jsx(Tooltip, Object.assign({ title: tooltip, arrow: true, placement: "top" }, { children: button }))) : (_jsx(_Fragment, { children: button }));
}
export function generateCellButton(cell, action, column, tooltip, localBasePath) {
    return (_jsx(TableButtonCell, { cell: cell, action: action, column: column, tooltip: tooltip, localBasePath: localBasePath }));
}
