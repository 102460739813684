var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu, MenuItem, MenuList, IconButton, Divider, ListItemIcon, ListItemText, Tooltip, CircularProgress, Box, Typography, } from "@mui/material";
import { DCBrowserStore } from "../../DatacenterBrowser";
import { capitalizeFirstLetter } from "../../FormGenerator/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { shallow } from "zustand/shallow";
import { dispatchConfirmationDialogEvent } from "../../utils";
import { useCookies } from "react-cookie";
function AddProjectMenuItem({ handleAddProject, }) {
    return (_jsx(_Fragment, { children: _jsxs(MenuItem, Object.assign({ onClick: handleAddProject, dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx(AddCircleOutlineOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "New project" })] })) }));
}
function ProjectMenuItem({ selected, projectName, handleSelectProject, handleDeleteProject, }) {
    return (_jsxs(MenuItem, Object.assign({ dense: true, selected: selected, onClick: handleSelectProject }, { children: [_jsx(ListItemIcon, { children: _jsx(FolderOpenOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: _jsx(Typography, Object.assign({ variant: "inherit", noWrap: true }, { children: capitalizeFirstLetter(projectName.replace(/_/g, " ")) })) }), projectName !== "default_project" && (_jsx(ListItemIcon, { children: _jsx(Tooltip, Object.assign({ title: "Delete project", arrow: true, placement: "right" }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: (e) => {
                            e.stopPropagation();
                            handleDeleteProject();
                        }, sx: {
                            ml: 2,
                            color: "rgb(172, 0, 0)",
                        } }, { children: _jsx(DeleteIcon, { fontSize: "small" }) })) })) }))] })));
}
export function ProjectsMenu({ anchorEl, isMenuOpen, userId, handleCloseMenu, handleAddProject, }) {
    const [project, sessionProjects] = DCBrowserStore((state) => [state.project, state.sessionProjects], shallow);
    const [, setCookie, removeCookie] = useCookies(["PM_PROJECT"]);
    const cookieOptions = {
        path: "/",
        domain: process.env.NODE_ENV === "development"
            ? undefined
            : process.env.REACT_APP_BACKEND_URL
                ? `.${process.env.REACT_APP_BACKEND_URL.replace("https://", "")}`
                : undefined,
        secure: process.env.NODE_ENV === "development" ? false : true,
        sameSite: "Strict",
        maxAge: 86400, // 86400 s = 90 days
    };
    const handleSelectProject = (projectName) => {
        DCBrowserStore.getState().setProject(projectName, userId);
        setCookie("PM_PROJECT", projectName, cookieOptions);
        handleCloseMenu();
    };
    const handleDeleteProject = (projectName) => __awaiter(this, void 0, void 0, function* () {
        // Ask confirmation
        const confirmMessage = `Are you sure you want to delete project '${capitalizeFirstLetter(projectName.replace(/_/g, " "))}'? All the jobs and results associated to this project will be removed.`;
        const ok = yield dispatchConfirmationDialogEvent({
            message: confirmMessage,
        });
        if (!ok)
            return;
        // Delete project and associated job results
        DCBrowserStore.getState().removeSessionProject(projectName, userId);
        removeCookie("PM_PROJECT", cookieOptions);
    });
    return (_jsx(Menu, Object.assign({ anchorEl: anchorEl, open: isMenuOpen, onClose: handleCloseMenu }, { children: sessionProjects === undefined ? (_jsx(Box, Object.assign({ sx: { width: "10rem", display: "flex", justifyContent: "center" } }, { children: _jsx(CircularProgress, { size: "20px" }) }))) : (_jsxs(MenuList, Object.assign({ dense: true }, { children: [sessionProjects.map((projectName) => (_jsx(ProjectMenuItem, { projectName: projectName, selected: projectName === project, handleSelectProject: () => {
                        handleSelectProject(projectName);
                    }, handleDeleteProject: () => handleDeleteProject(projectName) }, projectName))), _jsx(Divider, {}), _jsx(AddProjectMenuItem, { handleAddProject: handleAddProject })] }))) })));
}
