import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemIcon, ListItemText, } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CloseIcon from "@mui/icons-material/Close";
function UserData({ userInfo }) {
    return (_jsxs(List, Object.assign({ dense: true, sx: { "& .MuiListItem-root": { pl: 0 } } }, { children: [_jsxs(ListItem, { children: [_jsx(ListItemIcon, Object.assign({ style: { minWidth: "30px" } }, { children: _jsx(AccountCircleIcon, {}) })), _jsx(ListItemText, { primary: userInfo.username })] }), _jsxs(ListItem, { children: [_jsx(ListItemIcon, Object.assign({ style: { minWidth: "30px" } }, { children: _jsx(MailOutlineIcon, {}) })), _jsx(ListItemText, { primary: userInfo.email })] })] })));
}
export function UserSettingsDialog({ open, handleClose, userInfo, }) {
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ variant: "h5" }, { children: ["Account Information", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, { children: _jsx(_Fragment, { children: _jsx(UserData, { userInfo: userInfo }) }) })] })));
}
