var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { findLoadColumnWithRepeatedFile, joinMeanAndStdColumns, showInExponentialNotation, } from "./utils";
import Papa from "papaparse";
import { generateCellButton } from "./TableButtonCell";
export function parseCSVString(csvString) {
    return new Promise((resolve, reject) => {
        Papa.parse(csvString, {
            header: true,
            dynamicTyping: true,
            delimiter: ",",
            quotechar: '"',
            complete: (result) => {
                resolve(result.data);
            },
            error: (error) => {
                reject(new Error("Error parsing CSV: " + error.message));
            },
        });
    });
}
export function parseCSVFile(tableFile) {
    return new Promise((resolve, reject) => {
        const data = [];
        Papa.parse(tableFile, {
            header: true,
            delimiter: ",",
            newline: "",
            dynamicTyping: true,
            preview: 0,
            encoding: "",
            // worker: true,
            comments: false,
            quotechar: '"',
            complete: () => {
                resolve(data);
            },
            error: function (e) {
                reject(new Error("Error parsing CSV: " + e.message));
            },
            skipEmptyLines: true,
            chunk: function (result, handle) {
                if (result.data) {
                    data.push(...result.data);
                }
            },
            chunkSize: 1024 * 1024,
            fastMode: false,
        });
    });
}
export function formatTable(tablearray, tableRowButton, localBasePath, loadOnRowClick) {
    return __awaiter(this, void 0, void 0, function* () {
        const exRow = tablearray[0];
        joinMeanAndStdColumns(tablearray);
        let repeatedFileCols;
        if (loadOnRowClick)
            repeatedFileCols = findLoadColumnWithRepeatedFile(tablearray, loadOnRowClick);
        const smilesColumn = Object.keys(exRow).find((col) => col.toLowerCase() === "smiles");
        let svgColumns = smilesColumn ? [smilesColumn] : [];
        const columns = Object.keys(exRow).map((header) => {
            const hasRepeatedFileLoad = repeatedFileCols && repeatedFileCols.includes(header.toLowerCase());
            return {
                accessorFn: (r) => r[header],
                header: header ? header : " ",
                minSize: 80,
                cell: (c) => showInExponentialNotation(c.getValue()),
                enableColumnFilter: !svgColumns.includes(header),
                isNumber: typeof exRow[header] === "number",
                hasRepeatedFileLoad: hasRepeatedFileLoad,
            };
        });
        if (tableRowButton) {
            columns.unshift({
                accessorFn: (r) => "_action_button",
                header: " ",
                size: 30,
                cell: (c) => generateCellButton(c, tableRowButton.action, tableRowButton.column, tableRowButton.tooltip, localBasePath),
                enableColumnFilter: false,
            });
        }
        const rows = tablearray;
        return { columns: columns, rows: rows, svgColumns: svgColumns };
    });
}
