// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { FileType } from "../../..";
import { selectSystemByIndexArray } from "../../stateTree";
export function createGroup(vss, positionInTreeArr, groupName) {
    const tree = vss.getState().loaded_structures;
    const newtree = produce(tree, (draft) => {
        const systemsInGroup = [];
        let siblingsArray = [];
        const sortedPositions = positionInTreeArr
            .sort((a, b) => a - b)
            .reverse();
        const allPositionsStringified = sortedPositions.map((e) => JSON.stringify(e));
        sortedPositions.forEach((positionInTree) => {
            // Skip systems inside selected groups. They will be moved as a whole group, not as individual systems
            if (allPositionsStringified.includes(JSON.stringify(positionInTree.slice(0, -1))))
                return;
            const system = selectSystemByIndexArray(positionInTree, tree);
            const systPosInParent = positionInTree[positionInTree.length - 1];
            const systParentId = positionInTree.slice(0, -1);
            const systParent = selectSystemByIndexArray(systParentId, draft);
            if (systParent) {
                if (!systParent.children)
                    return;
                siblingsArray = systParent.children;
            }
            else {
                siblingsArray = draft;
            }
            siblingsArray.splice(systPosInParent, 1);
            systemsInGroup.unshift(system);
        });
        siblingsArray.unshift({
            name: groupName,
            type: FileType.group,
            children: systemsInGroup,
        });
    });
    vss.getState().set_loaded_structures(newtree);
}
