import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
function lightenColor(hex) {
    const percent = 0.5;
    // Removing '#' if present
    hex = hex.replace(/^#/, "");
    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    // Calculate the lighter color
    r = Math.floor(r + (255 - r) * percent);
    g = Math.floor(g + (255 - g) * percent);
    b = Math.floor(b + (255 - b) * percent);
    // Ensure values stay within 0-255
    r = r < 0 ? 0 : r > 255 ? 255 : r;
    g = g < 0 ? 0 : g > 255 ? 255 : g;
    b = b < 0 ? 0 : b > 255 ? 255 : b;
    // Convert back to hex
    const lighterHex = `#${(r * 65536 + g * 256 + b)
        .toString(16)
        .padStart(6, "0")}`;
    return lighterHex;
}
export function DataVizTabs({ tabs, activeTab, callback, }) {
    const [tabValue, setTabValue] = useState(activeTab);
    useEffect(() => {
        setTabValue(activeTab);
    }, [activeTab]);
    const handleChangeTab = (event) => {
        const eTarget = event.target;
        const newVal = eTarget.textContent;
        if (!newVal)
            return;
        setTabValue(newVal);
        callback(newVal);
    };
    return (_jsx(Tabs, Object.assign({ value: tabValue, onChange: handleChangeTab, variant: "scrollable", scrollButtons: "auto" }, { children: tabs.map((tab) => (_jsx(Tab, { label: tab.name, value: tab.name, icon: tab.color ? (_jsx(CircleIcon, { sx: {
                    fontSize: "1em",
                    color: tab.name === activeTab
                        ? tab.color
                        : lightenColor(tab.color),
                    mb: "3px",
                } })) : undefined, iconPosition: "end" }, `dataviz-tab-${tab.name}`))) })));
}
