var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { userAuthStore } from "./userAuthState";
function apicallResult(apiCall) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield apiCall;
        if (!result.ok) {
            let error;
            try {
                const resultJson = yield result.json();
                error = `${resultJson.message}`;
            }
            catch (e) {
                error = "unknown error";
            }
            return { success: false, error: error };
        }
        return { success: true, result: result };
    });
}
function refreshAccessToken() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield apicallResult(fetch(`${window.location.origin}/v2/auth/refresh-token`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }));
    });
}
export function executeWithTokenRefresh(apiCall, noMessages) {
    return __awaiter(this, void 0, void 0, function* () {
        // If call is successful, return result. Otherwise check if it's because of the access token
        const { success: call1Success, error: call1Error, result: call1Result, } = yield apicallResult(apiCall);
        if (call1Success)
            return { success: true, result: call1Result };
        if (!call1Error.startsWith("Authentication failed."))
            return { success: false, error: call1Error };
        // Call failed because of the access token. Refresh the token and try again
        const { success: refreshSuccess, error: refreshError } = yield refreshAccessToken();
        let errorMessage;
        let logout = false;
        if (refreshSuccess) {
            const { success: call2Success, error: call2Error, result: call2Result, } = yield apicallResult(apiCall);
            if (call2Success)
                return { success: true, result: call2Result };
            else {
                errorMessage = call2Error;
                if (call1Error.startsWith("Authentication failed.")) {
                    logout = true;
                }
            }
        }
        else {
            errorMessage = refreshError;
            logout = true;
        }
        // If the 2nd call still has an error, or we failed to update the access token, log out,
        if (logout) {
            userAuthStore.getState().logout(!noMessages);
        }
        return { success: false, error: errorMessage };
    });
}
