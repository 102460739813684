var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setVisibility } from "../3dViewer/VisibilityToggle";
import produce from "immer";
import { downloadDatasetFiles, getDatasets } from "../apps/results/fetchDcData";
export function setSessionSystemParamsByFileName(vss, loadedSystFiles, molstar) {
    const systems = vss.getState().loaded_structures;
    let isLoaded = false;
    const cellRefs = [];
    const updated_systems = produce(systems, (draftSystems) => {
        draftSystems.forEach((system) => {
            const nodeLoaded = setSessionSystemParamsByFileNameNode(system, loadedSystFiles, molstar);
            isLoaded = nodeLoaded.isActive ? true : isLoaded;
            cellRefs.push(...nodeLoaded.cellRefs);
        });
    });
    vss.getState().set_loaded_structures(updated_systems);
    return { isLoaded: isLoaded, cellRefs: cellRefs };
}
function setSessionSystemParamsByFileNameNode(system, loadedSystFiles, molstar) {
    const systemFiles = system.files;
    let isActive = false;
    let isVisible = false;
    let cellRefs = [];
    if (systemFiles) {
        const foundLoadedSyst = loadedSystFiles.find((loadedSyst) => systemFiles.some((f) => loadedSyst.paths.includes(f)));
        const systCellRefs = system.cellRef;
        if (foundLoadedSyst) {
            cellRefs = systCellRefs ? systCellRefs : [];
            isVisible = foundLoadedSyst.visibility;
            system.visibility = isVisible;
            system.archived = false;
            isActive = true;
        }
        else {
            isVisible = false;
            system.visibility = isVisible;
            system.archived = true;
        }
        if (systCellRefs && systCellRefs.length > 0) {
            setVisibility(molstar, systCellRefs, isVisible);
        }
    }
    else if (system.children) {
        for (let i = 0; i < system.children.length; i++) {
            const nodeLoaded = setSessionSystemParamsByFileNameNode(system.children[i], loadedSystFiles, molstar);
            isActive = nodeLoaded.isActive ? true : isActive;
            isVisible = nodeLoaded.isVisible ? true : isVisible;
            cellRefs.push(...nodeLoaded.cellRefs);
        }
        system.archived = !isActive;
        system.visibility = isVisible;
    }
    return { isActive: isActive, cellRefs: cellRefs, isVisible: isVisible };
}
export function downloadTargetData(filePath, targetID, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const _targetID = targetID.toUpperCase();
        const targetFilePath = `${filePath}/${_targetID}`;
        const datasets = yield getDatasets({
            remotepath: `DataPackage/${targetID}`,
        });
        if (!datasets || datasets.length === 0)
            return;
        const dsId = datasets[0]["id"];
        yield downloadDatasetFiles(dsId, pyodide, undefined, targetFilePath);
        const summaryData = yield pyodide.RunPythonAsync({
            context: { targetFilePath },
            script: `
      from js import  targetFilePath
      read_data_portal_target_summary(targetFilePath)
      `,
        });
        return summaryData;
    });
}
