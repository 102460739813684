import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Checkbox, Grid, Fade } from "@mui/material";
export function SystemCheckbox({ boxKey, onCheckSystem, checkedSystems, areSystemsCheckboxActive, }) {
    return (_jsx(Fade, Object.assign({ in: areSystemsCheckboxActive, unmountOnExit: true }, { children: _jsx(Checkbox, { checked: checkedSystems && checkedSystems.includes(boxKey), size: "small", sx: {
                "& .MuiSvgIcon-root": {
                    width: "1em",
                    height: "1em",
                    color: "primary.light",
                },
            }, onChange: (e) => onCheckSystem ? onCheckSystem(e.target.checked, boxKey) : null }) })));
}
export function ToggleAllGroups({ areSystemsCheckboxActive, onCheckToggleAll, isToggleAllChecked, }) {
    const handleChange = (e) => {
        const isChecked = e.target.checked;
        onCheckToggleAll(isChecked);
    };
    return (_jsx(Fade, Object.assign({ in: areSystemsCheckboxActive, unmountOnExit: true }, { children: _jsx(Grid, Object.assign({ container: true, alignItems: "center" }, { children: _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Checkbox, { sx: { "& .MuiSvgIcon-root": { color: "primary.light" } }, checked: isToggleAllChecked, size: "small", onChange: handleChange }) })) })) })));
}
