// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
/** The styled() function takes the original component and applies some extra
 * styling rules based on the available theme and the props passed.
 * Notice that the 'shouldForwardProp' parameter means
 * "pass the prop to the original component as is".
 */
export const CollapsibleDrawerBottom = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => (Object.assign(Object.assign({ flexShrink: 0, whiteSpace: "nowrap", overflowX: "hidden", overflowY: "hidden" }, (open && Object.assign(Object.assign({}, openingTransition(theme)), { "& .MuiDrawer-paper": openingTransition(theme) }))), (!open && Object.assign(Object.assign({}, closingTransition(theme)), { "& .MuiDrawer-paper": closingTransition(theme) })))));
const openingTransition = (theme) => ({
    height: "39em",
    width: `calc(${theme.spacing(7)} + 25em)`,
    transition: widthTransition(theme),
});
const closingTransition = (theme) => ({
    height: 0,
    width: "100px",
    transition: widthTransition(theme),
});
const widthTransition = (theme) => theme.transitions.create("height", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
});
