// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { TableClickAction } from "..";
function findToggleColnamesInConf(rowActions) {
    return rowActions === null || rowActions === void 0 ? void 0 : rowActions.filter((act) => act.action === TableClickAction.toggleVisibility ||
        act.action === TableClickAction.loadFile).reduce((allCols, act) => {
        // const addCols = act.column.filter((col) => col !== "protein");
        return [...allCols, ...act.column];
    }, []);
}
function findToggleColnamesInTable(activeTable, toggleVizColnamesConf, repeatedFileCols) {
    let toggleVizColnames = [];
    activeTable.columns.forEach((col) => {
        const colName = col["header"];
        const isToggleCol = typeof colName === "string" &&
            toggleVizColnamesConf.some((toggleCol) => toggleCol.toLowerCase() === colName.toLowerCase()) &&
            !(repeatedFileCols === null || repeatedFileCols === void 0 ? void 0 : repeatedFileCols.includes(colName));
        if (isToggleCol)
            toggleVizColnames.push(colName);
    });
    return toggleVizColnames;
}
function cleanFilePath(originalString, substringToRemove) {
    if (substringToRemove) {
        const _substringToRemove = substringToRemove.endsWith("/")
            ? substringToRemove
            : substringToRemove + "/";
        if (originalString.startsWith(_substringToRemove)) {
            return originalString.slice(_substringToRemove.length);
        }
    }
    if (originalString.startsWith("dc://")) {
        const fileName = originalString.split("=").pop();
        if (fileName)
            return fileName;
    }
    return originalString;
}
export function rowIsActive(row, toggleVizColnames, visibleSystemsFiles, basePath, DCtable) {
    const rowToggleData = toggleVizColnames.map((colName) => row.original[colName]);
    const rowIsActive = visibleSystemsFiles.some((systFiles) => {
        const systFiles_relpath = basePath || DCtable
            ? systFiles.map((fpath) => cleanFilePath(fpath, basePath))
            : systFiles;
        return rowToggleData.some((rowSystem) => {
            if (!rowSystem)
                return false;
            return rowSystem.charAt(0) === "/"
                ? systFiles.includes(rowSystem)
                : systFiles_relpath.includes(rowSystem);
        });
    });
    return rowIsActive;
}
export function getToggleVizColnames(rowActions, activeTable) {
    if (!activeTable)
        return [];
    const toggleVizColnamesConf = rowActions
        ? findToggleColnamesInConf(rowActions)
        : [];
    if (toggleVizColnamesConf.length === 0)
        return [];
    const repeatedFileCols = activeTable === null || activeTable === void 0 ? void 0 : activeTable.columns.filter((col) => col.hasRepeatedFileLoad).map((col) => col.header);
    const toggleVizColnames = findToggleColnamesInTable(activeTable, toggleVizColnamesConf, repeatedFileCols);
    return toggleVizColnames;
    // if (toggleVizColnames.length <= 1) return toggleVizColnames;
    // return [];
}
