import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 170,
    [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 100,
    },
    "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        "& .MuiImageBackdrop-root": {
            opacity: 0.4,
        },
        // "& .MuiImageMarked-root": {
        //   opacity: 0,
        // },
        "& .MuiTypography-root": {
            // borderBottom: "4px solid currentColor",
            fontSize: "1.15rem",
        },
    },
}));
const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
});
const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
}));
const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create("opacity"),
}));
// const ImageMarked = styled("span")(({ theme }) => ({
//   height: 3,
//   width: 18,
//   backgroundColor: theme.palette.common.white,
//   position: "absolute",
//   bottom: -2,
//   left: "calc(50% - 9px)",
//   transition: theme.transitions.create("opacity"),
// }));
export default function TargetResultButtons({ datasets, onResultSelection, disabled, }) {
    return (_jsx(Grid, Object.assign({ container: true, item: true, direction: "row", spacing: 4, justifyContent: "space-evenly", alignItems: "center" }, { children: datasets.map((dataType) => (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsxs(ImageButton, Object.assign({ focusRipple: true, style: {
                    minWidth: "20em",
                    maxWidth: "40em",
                }, onClick: () => {
                    onResultSelection(dataType);
                }, disabled: disabled }, { children: [_jsx(ImageSrc, { style: {
                            backgroundImage: `url(${dataType.name.toLowerCase() in imageURLs
                                ? imageURLs[dataType.name.toLowerCase()]
                                : ""})`,
                            backgroundColor: "rgba(255,255,255,0.6)",
                            backgroundBlendMode: "lighten",
                        } }), _jsx(ImageBackdrop, { className: "MuiImageBackdrop-root" }), _jsx(Image, { children: _jsx(Typography, Object.assign({ component: "span", variant: "subtitle1", color: "inherit", sx: {
                                position: "relative",
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            } }, { children: dataType["name"] })) })] })) }), `dataportal-data-result-${dataType.name.replace(" ", "_")}`))) })));
}
const imageURLs = {
    "conformational analysis": "/static/images/isca_btn.png",
    "binding pockets": "/static/images/cs_btn.png",
};
