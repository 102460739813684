// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
const supportedFiles = {
    coordinates_static: [
        "pdb",
        "sdf",
        "mae",
        "mol2",
        "xyz",
        "ent",
        "pdbqt",
        "crd",
        "cif",
        "bcif",
        "prepi",
        "mmtf",
        "mmtf.gz",
        "gjf",
        "alphafold",
        "coor",
    ],
    coordinates_trajectory: ["xtc", "xsc", "trr", "dcd", "netcdf"],
    topology: ["psf", "prmtop", "prm", "top", "rtf"],
    volume: ["cube"],
    plots: ["plot.json", "png", "svg"],
    tables: ["csv"],
    MSAs: ["msa.csv"],
    loading_batch: ["pdb"],
    instructions: ["nap", "pmv"],
};
export default supportedFiles;
