import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Route, useLocation } from "wouter";
import { Box, Typography, Grid, List, IconButton } from "@mui/material";
import { NestedRoutes } from "../utils/NestedRoutes";
import { NAPAccordion } from "../3dViewer/Controls/Accordion";
import { LocalVideo } from "./Video";
import { docsSections as docsSectionsAll } from "./constants/constants";
import { NestedMenuSection } from "./DocsMenu";
import { DownloadFilesBtn } from "./DownloadFilesBtn";
import CloseIcon from "@mui/icons-material/Close";
function DocsSubSectionAccordion({ name, children, }) {
    const defaultOpen = name === "Description";
    const [open, setOpen] = useState(defaultOpen);
    return (_jsx(NAPAccordion, Object.assign({ name: name, open: open, callback: () => setOpen((pre) => !pre), variant: "undefined", summaryline: true }, { children: _jsx(Box, Object.assign({ sx: { pt: 4 } }, { children: children })) })));
}
function DocsSectionNAP({ name, videos, files, pyodide, }) {
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "h4" }, { children: name })) })), !!files && _jsx(DownloadFilesBtn, { tags: files.tags }), _jsx(Grid, Object.assign({ item: true, container: true, direction: "column", spacing: 2 }, { children: videos.length > 1 ? (videos.map((vidInfo, i) => (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DocsSubSectionAccordion, Object.assign({ name: vidInfo.name ? vidInfo.name : "" }, { children: _jsx(_Fragment, { children: vidInfo.content.map((vidContent, j) => (_createElement(LocalVideo, Object.assign({}, vidContent, { key: `docs-viewer-subsection-${i}-${j}` })))) }) })) }), `docs-viewer-subsection-${i}`)))) : (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: videos[0].content.map((vidContent) => (_createElement(LocalVideo, Object.assign({}, vidContent, { key: `docs-viewer-subsection-0-0` })))) }))) }))] })));
}
function DocumentationMenu({ docsSections, desktopSections, }) {
    return (_jsx(List, { children: docsSections.map((docsSection) => (_createElement(NestedMenuSection, Object.assign({}, docsSection, { desktopSections: desktopSections, key: docsSection.name })))) }));
}
export function DocsSectionApps({ name, contents }) {
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 4, justifyContent: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "h4" }, { children: name })) })), _jsx(Grid, Object.assign({ item: true, container: true, direction: "column", spacing: 2, justifyContent: "flex-start", xs: "auto" }, { children: contents.length === 1 ? (_jsx(Grid, Object.assign({ item: true }, { children: contents[0].content }), `docs-app-section-${contents[0].name}`)) : (contents.map((cont) => (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(DocsSubSectionAccordion, Object.assign({ name: cont.name }, { children: cont.content })) }), `docs-app-section-${cont.name}`)))) }))] })));
}
export function DocsPolicies({ name, short_name, contents }) {
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 4, justifyContent: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "h4" }, { children: name })) })), _jsx(Grid, Object.assign({ item: true, container: true, direction: "column", spacing: 2, justifyContent: "flex-start", xs: "auto" }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: contents } }) }), `docs-policy-section-content`) }))] })));
}
function DocumentationContents({ pyodide, docsSections, desktopSections, }) {
    const subSections = [];
    docsSections.forEach((docsSection) => {
        switch (docsSection.name) {
            case "Viewer":
                docsSection.subSections.forEach((docsSubSection) => {
                    const _docsSubSection = docsSubSection;
                    const comp = (_jsx(Route, Object.assign({ path: _docsSubSection.id }, { children: _jsx(DocsSectionNAP, { name: _docsSubSection.name, videos: _docsSubSection.videos, files: _docsSubSection.files, pyodide: pyodide }) }), _docsSubSection.id));
                    subSections.push(comp);
                });
                break;
            case "Applications":
                docsSection.subSections.forEach((docsSubSection) => {
                    const _docsSubSection = docsSubSection;
                    const comp = (_jsx(Route, Object.assign({ path: _docsSubSection.id.toLocaleLowerCase() }, { children: _jsx(DocsSectionApps, Object.assign({}, _docsSubSection)) }), _docsSubSection.id));
                    subSections.push(comp);
                });
                break;
            default:
                docsSection.subSections.forEach((docsSubSection) => {
                    const _docsSubSection = docsSubSection;
                    const comp = (_jsx(Route, Object.assign({ path: _docsSubSection.id.toLocaleLowerCase() }, { children: _jsx(DocsPolicies, Object.assign({}, _docsSubSection)) }), _docsSubSection.id));
                    subSections.push(comp);
                });
        }
    });
    return (_jsxs(Box, Object.assign({ sx: { p: 4 } }, { children: [_jsx(Route, { path: "/docs" }), _jsx(NestedRoutes, Object.assign({ base: "/docs/" }, { children: subSections }))] })));
}
export function Documentation({ pyodide, desktopSections, }) {
    const _docsSections = desktopSections
        ? docsSectionsAll.filter((section) => section.name !== "Applications")
        : docsSectionsAll;
    const [, setLocation] = useLocation();
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: { position: "fixed", right: 10, top: 10 } }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: () => setLocation("/") }, { children: _jsx(CloseIcon, {}) })) })), _jsxs(Grid, Object.assign({ container: true, sx: { height: "calc(100% - 1.75em)" }, direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, sx: {
                            width: "11em",
                            minWidth: "11em",
                            bgcolor: "background.paper",
                            borderRight: 1,
                            borderColor: "divider",
                            overflowY: "auto",
                            height: "100%",
                        }, xs: "auto" }, { children: _jsx(DocumentationMenu, { docsSections: _docsSections, desktopSections: desktopSections }) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: { overflowY: "auto", overflowX: "auto", height: "100%" } }, { children: _jsx(DocumentationContents, { pyodide: pyodide, docsSections: docsSectionsAll, desktopSections: desktopSections }) }))] }))] }));
}
