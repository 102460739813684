import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateSelection } from "molstar/lib/mol-state";
export function getTrajectoryRef(molstar, cellRefs) {
    let trajectory;
    try {
        trajectory = StateSelection.findAncestorOfType(molstar.state.data.tree, molstar.state.data.cells, cellRefs[0], PluginStateObject.Molecule.Trajectory);
    }
    catch (e) {
        console.warn(e);
    }
    return trajectory === null || trajectory === void 0 ? void 0 : trajectory.transform.ref;
}
