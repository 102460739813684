var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
export function Filter({ column, table, }) {
    var _a, _b;
    if (!column.getCanFilter())
        return _jsx(_Fragment, {});
    const columnFilterValue = column.getFilterValue();
    return column.columnDef["isNumber"] ? (_jsxs(Grid, Object.assign({ container: true, direction: "row", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TableTextField, { type: "number", value: (_a = columnFilterValue === null || columnFilterValue === void 0 ? void 0 : columnFilterValue[0]) !== null && _a !== void 0 ? _a : "", onChange: (value) => column.setFilterValue((old) => [value, old === null || old === void 0 ? void 0 : old[1]]), placeholder: `Min` }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TableTextField, { type: "number", value: (_b = columnFilterValue === null || columnFilterValue === void 0 ? void 0 : columnFilterValue[1]) !== null && _b !== void 0 ? _b : "", onChange: (value) => column.setFilterValue((old) => [old === null || old === void 0 ? void 0 : old[0], value]), placeholder: `Max` }) }))] }))) : (_jsx(TableTextField, { type: "text", value: (columnFilterValue !== null && columnFilterValue !== void 0 ? columnFilterValue : ""), onChange: (value) => column.setFilterValue(value), placeholder: `Search...` }));
}
// A debounced input react component
function TableTextField(_a) {
    var { value: initialValue, onChange, debounce = 500 } = _a, props = __rest(_a, ["value", "onChange", "debounce"]);
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    useEffect(() => {
        onChange(value);
    }, [value]);
    return (_jsx(TextField, Object.assign({}, props, { value: value, onChange: (e) => setValue(e.target.value), size: "small", 
        // fullWidth
        inputProps: {
            style: {
                fontSize: "0.8rem",
                padding: 5,
            },
        } })));
}
