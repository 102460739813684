// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { create } from "zustand";
import { NAPTableStore } from "./tables.store";
export var PlotType;
(function (PlotType) {
    PlotType["image"] = "image";
    PlotType["interactive"] = "interactive";
})(PlotType || (PlotType = {}));
export const NAPPlotStore = create()((set, get) => ({
    plots: [],
    activePlotIdx: 0,
    set_activePlotIdx: (x) => set({ activePlotIdx: x }),
    set_plots: (x) => set({ plots: x }),
    add_plot: (x) => {
        set((state) => ({
            plots: [...state.plots, x],
            activePlotIdx: get().plots.length,
        }));
    },
    del_plot: (idx, cb) => set((state) => {
        const updated_plots = produce(state.plots, (draft) => {
            draft.splice(idx, 1);
        });
        if (updated_plots.length === 0 &&
            NAPTableStore.getState().tables.length === 0) {
            cb();
        }
        return { plots: updated_plots, activePlotIdx: 0 };
    }),
}));
