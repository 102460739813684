function createFileNode(dsId, name, fileMap, parentId = null, fullPath = "") {
    if (!(dsId in fileMap)) {
        fileMap[dsId] = {
            id: dsId,
            name: name,
            dsfullpath: fullPath,
            isDir: true,
            childrenIds: [],
            childrenCount: 0,
        };
        if (parentId !== null) {
            const countPre = fileMap[parentId].childrenCount;
            fileMap[dsId].parentId = parentId;
            fileMap[parentId].childrenIds.push(dsId);
            fileMap[parentId].childrenCount = countPre ? countPre + 1 : 1;
        }
    }
    return dsId;
}
function createFolders(pieces, fileMap, parentId, parentPath = "") {
    for (let i = 0; i < pieces.length; i++) {
        const fullPath = parentPath + pieces.slice(0, i + 1).join("/");
        parentId = createFileNode(fullPath, pieces[i], fileMap, parentId);
    }
    return parentId;
}
export function createFiletree(datasets = [], truncate = "", rootdir = "") {
    const filetree = {
        rootFolderId: rootdir,
        fileMap: {
            [rootdir]: {
                id: rootdir,
                name: rootdir,
                dsfullpath: "",
                isDir: true,
                childrenIds: [],
                childrenCount: 0,
            },
        },
    };
    const fileMap = filetree.fileMap;
    datasets.forEach((dataset) => {
        const files = dataset.files ? dataset.files : [];
        const path = dataset.filepath.replace(truncate, "");
        const pieces = path.split("/");
        let superparent = createFolders(pieces.slice(0, -1), fileMap, rootdir);
        superparent = createFileNode(`dc://${dataset.id}`, pieces[pieces.length - 1], fileMap, superparent, path);
        files.forEach((ff) => {
            if (ff.endsWith("/"))
                return;
            const pieces = ff.split("/");
            const currparent = createFolders(pieces.slice(0, -1), fileMap, superparent, path + "/");
            const dsId = `dc://${dataset.id}?files=${ff}`;
            fileMap[dsId] = {
                id: dsId,
                name: pieces[pieces.length - 1],
                dsfullpath: "",
                parentId: currparent,
                size: dataset.filesize,
                modDate: dataset.updated_at,
            };
            fileMap[currparent].childrenIds.push(dsId);
            const countPre = fileMap[currparent].childrenCount;
            fileMap[currparent].childrenCount = countPre ? countPre + 1 : 1;
        });
    });
    return filetree;
}
