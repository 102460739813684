// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var IsLoadingGroup;
(function (IsLoadingGroup) {
    IsLoadingGroup["all"] = "all";
    IsLoadingGroup["some"] = "some";
})(IsLoadingGroup || (IsLoadingGroup = {}));
export var FileType;
(function (FileType) {
    FileType["coordinates_group"] = "coordinates_group";
    FileType["coordinates_single"] = "coordinates_single";
    FileType["ensemble"] = "ensemble";
    FileType["trajectory"] = "trajectory";
    FileType["volume"] = "volume";
    FileType["group"] = "group";
    FileType["sdf"] = "sdf";
})(FileType || (FileType = {}));
