import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Slider } from "@mui/material";
import { useState, useEffect } from "react";
export function ControlledSlider({ currVal, minVal, maxVal, step, disabled, onUpdate, }) {
    /*
      Reminder: x in useState(x) means "set the value to x INITIALLY".
      Subsequent re-renders do not obey this instruction.
    */
    const [internalValue, setInternalValue] = useState(currVal);
    useEffect(() => {
        if (internalValue !== currVal)
            setInternalValue(currVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currVal]);
    const internalHandler = (_, newValue) => {
        if (typeof newValue === "number")
            setInternalValue(newValue);
        else
            throw new Error("Not a number!");
    };
    const handleSliderMouseUp = () => onUpdate(internalValue);
    return (_jsx(Slider, { disabled: disabled, value: internalValue, min: minVal, max: maxVal, step: step, onChange: internalHandler, onChangeCommitted: handleSliderMouseUp, valueLabelDisplay: "auto" }));
}
