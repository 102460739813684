var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { Fragment, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import CircleIcon from "@mui/icons-material/Circle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, Tooltip, Link, IconButton, Grid, } from "@mui/material";
import { Apps } from "../../GenericViewerState/SharedState";
import { statusToColor, jobListStore, } from "../../Sidebar/JobStatusSharedState";
import { DCBrowserStore } from "../../DatacenterBrowser/datacenterBrowser.store";
import { FilterJobs } from "./FilterJobs";
import { JobActions } from "./JobActions";
import { TypographyWithTooltipIfTruncated } from "../..//utils/TypographyWithTooltipIfTruncated";
import { jobIdToDatasetid } from "./utils";
import { QueueStatus } from "./QueueStatus";
import { userAuthStore } from "../../Authentication/userAuthState";
export function JobsStatus({ pyodide }) {
    const [filterBy, setFilterBy] = useState("name");
    const [filterStr, setFilterStr] = useState("");
    const [jobList] = jobListStore((s) => [s.jobList]);
    const filteredJobList = useMemo(() => {
        return jobList.filter((job) => {
            const jobAttr = job[filterBy];
            if (jobAttr === undefined || typeof jobAttr !== "string")
                return true;
            return jobAttr.toLowerCase().indexOf(filterStr.toLowerCase()) > -1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBy, filterStr, jobList]);
    return (_jsxs(Box, Object.assign({ sx: { py: 1 } }, { children: [_jsx(Box, Object.assign({ sx: { px: 2 } }, { children: _jsx(PanelTitle, {}) })), _jsx(QueueStatus, {}), _jsx(Box, { children: _jsx(FilterJobs, { filterStr: filterStr, setFilterStr: setFilterStr, setFilterBy: setFilterBy }) }), _jsxs(Table, Object.assign({ size: "small", stickyHeader: true, sx: { px: 2 } }, { children: [_jsx(TableHeader, {}), _jsx(TableBody, { children: filteredJobList.map((job) => (_jsx(JobTableRow, { job: job, pyodide: pyodide }, job.traceId))) })] }))] })));
}
function JobTableRow({ job, pyodide, isChildren, }) {
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    const appName = job.container.split("_")[0];
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, Object.assign({ sx: rowStyle }, { children: [isChildren ? (_jsx(TableCell, { sx: Object.assign(Object.assign({}, cellStyle), { borderRight: "1px solid rgba(224, 224, 224, 1)" }) })) : null, _jsx(TableCell, Object.assign({ component: "th", scope: "row", sx: Object.assign(Object.assign({}, cellStyle), { maxWidth: "8em" }), colSpan: isChildren ? 1 : 2 }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", justifyContent: isChildren ? "flex-end" : "center", wrap: "nowrap" }, { children: [_jsx(JobIdCell, { job: job, isChildren: isChildren }), isChildren ? null : _jsx(CopyJobIdButton, { job: job })] })) })), _jsx(TableCell, Object.assign({ sx: cellStyle }, { children: _jsx(TypographyWithTooltipIfTruncated, { arrow: true, 
                            // enterDelay={1000}
                            title: appName, placement: "top", typopgraphyText: appName, typographySx: { fontSize: "0.875rem" } }) })), _jsx(TableCell, Object.assign({ sx: cellStyle }, { children: job.name ? (_jsx(TypographyWithTooltipIfTruncated, { arrow: true, 
                            // enterDelay={1000}
                            title: job.name, placement: "top", typopgraphyText: job.name, typographySx: {
                                fontSize: "0.875rem",
                            } })) : ("-") })), _jsx(TableCell, Object.assign({ align: "center", sx: Object.assign({}, cellStyle) }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(StatusIcon, { status: job.status, errorInfo: job.error_info, progressper: job.progressper, progressinfo: job.progressinfo }), _jsx(JobActions, { job: job })] })) }))] }), job.traceId), job.children
                ? job.children.map((jobchild) => (_jsx(JobTableRow, { job: jobchild, pyodide: pyodide, isChildren: true }, jobchild.traceId)))
                : null] }));
}
function StatusIcon({ status, errorInfo, progressper, progressinfo, }) {
    const statusInfo = status !== "Completed" && (progressper || progressinfo) ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: "bold", fontSize: 12 } }, { children: status })), progressper ? (_jsxs(Typography, Object.assign({ variant: "body2", sx: { fontSize: 11 } }, { children: ["Progress: ", progressper] }))) : null, progressinfo ? (_jsx(Typography, Object.assign({ variant: "body2", sx: { fontSize: 11 } }, { children: progressinfo }))) : null] })) : (status);
    let title = errorInfo ? errorInfo : statusInfo;
    const color = statusToColor[status];
    return (_jsx(Grid, Object.assign({ item: true, xs: true, sx: { color: color ? color : "#fb8500" } }, { children: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", title: title }, { children: _jsx(CircleIcon, { fontSize: "small", sx: { verticalAlign: "middle" } }) })) })));
}
const cellStyle = {
    borderBottom: "none",
    overflow: "hidden",
    maxWidth: "7em",
    textOverflow: "ellipsis",
    paddingRight: 1,
    paddingLeft: 1,
};
const rowStyle = {
    cursor: "default",
    "&:last-child td, &:last-child th": { border: 0 },
};
const PanelTitle = () => (_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { pt: 1 } }, { children: "Job Tracking" })));
const TableHeader = () => (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, Object.assign({ sx: { fontWeight: "bold" }, colSpan: 2 }, { children: "Job ID" })), _jsx(TableCell, Object.assign({ sx: { fontWeight: "bold" } }, { children: "Tool" })), _jsx(TableCell, Object.assign({ sx: { fontWeight: "bold" } }, { children: "Name" })), _jsx(TableCell, Object.assign({ sx: { fontWeight: "bold" } }, { children: "Status" }))] }) }));
function openJobDataset(props) {
    return __awaiter(this, void 0, void 0, function* () {
        const { setLocation, setCurrentFolderId, job, project } = props;
        const userInfo = userAuthStore.getState().userInfo;
        if (!userInfo)
            return;
        const datasetsid = yield jobIdToDatasetid(job.traceId, userInfo.userId, project);
        if (!datasetsid)
            return;
        const fileID = `dc://${datasetsid}`;
        setCurrentFolderId(fileID);
        setLocation(Apps.datacenter);
    });
}
const JobIdCell = ({ job, isChildren }) => {
    const [, setLocation] = useLocation();
    const [setCurrentFolderId] = DCBrowserStore((s) => [s.setCurrentFolderId], shallow);
    const [project] = DCBrowserStore((state) => [state.project], shallow);
    const cb = () => {
        openJobDataset({
            job,
            setLocation,
            setCurrentFolderId,
            project,
        });
    };
    const statusOpenFolder = ["Sleeping", "Completed", "Error"];
    return (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ sx: Object.assign(Object.assign({}, cellStyle), { fontFamily: "monospace", fontSize: "14px", p: 0 }) }, { children: !isChildren && statusOpenFolder.includes(job.status) ? (_jsx(Link, Object.assign({ sx: { cursor: "pointer" }, onClick: cb }, { children: job.shortTraceId }))) : (_jsx(_Fragment, { children: job.shortTraceId })) })) })));
};
const CopyJobIdButton = ({ job }) => {
    const [justCopied, setJustCopied] = useState(false);
    return (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Tooltip, Object.assign({ title: justCopied ? "Job ID copied!" : "Click to copy the job ID", placement: "top", onClose: () => setJustCopied(false) }, { children: _jsx(IconButton, Object.assign({ onClick: () => {
                    navigator.clipboard.writeText(job.traceId);
                    setJustCopied(true);
                } }, { children: _jsx(AssignmentIcon, { fontSize: "small" }) })) })) })));
};
