// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { TableClickAction } from "..";
import { NAPTableStore } from "../StateStores/tables.store";
import { cameraFocus } from "../3dViewer/Controls/CameraControls/cameraFocus";
import { get_system_by_cellref, get_representation_ref_by_name } from "..";
export function findSystemFileInTree(systems, fileName) {
    let fileCellRefs;
    systems.forEach((system) => {
        const foundCellRefs = findSystemFileInTreeNode(system, fileName);
        if (foundCellRefs)
            fileCellRefs = foundCellRefs;
    });
    return fileCellRefs;
}
function findSystemFileInTreeNode(system, fileName) {
    if (system.files) {
        if (system.files.includes(fileName)) {
            return system;
        }
    }
    else if (system.children) {
        let result;
        for (let i = 0; i < system.children.length && result == null; i++) {
            result = findSystemFileInTreeNode(system.children[i], fileName);
        }
        return result;
    }
}
function assignRowToSystem(systems, _row) {
    const systemsColumn = "Path";
    if (!(systemsColumn in _row))
        return;
    const filePath = _row[systemsColumn];
    const fileName = filePath.split("/").pop();
    if (!fileName)
        return;
    const fileSyst = findSystemFileInTree(systems, fileName);
    const fileCellRefs = fileSyst === null || fileSyst === void 0 ? void 0 : fileSyst.cellRef;
    if (fileCellRefs)
        _row["cellRefs"] = fileCellRefs;
}
function assignRowsToSystem(systems, rows) {
    rows.forEach((row) => {
        assignRowToSystem(systems, row);
    });
}
export const syncTableToSystems = (systems) => {
    const t = NAPTableStore.getState().tables;
    const updated_tables = produce(t, (draft) => {
        draft.forEach((table) => {
            assignRowsToSystem(systems, table.rows);
        });
    });
    NAPTableStore.getState().set_tables(updated_tables);
};
export function focusOnSystem(molstar, cellRefs, systems) {
    if (cellRefs.length === 0)
        return;
    let foundRef;
    for (const cellRef of cellRefs) {
        const system = get_system_by_cellref(systems, cellRef);
        if (system) {
            foundRef = get_representation_ref_by_name(system, "Ligand");
            if (foundRef)
                break;
            foundRef = get_representation_ref_by_name(system, "Protein");
            if (foundRef)
                break;
            foundRef = get_representation_ref_by_name(system, "All");
            if (foundRef)
                break;
            const firstRef = system.representations
                ? system.representations[0]
                : undefined;
            foundRef =
                firstRef.refs && firstRef.refs.length > 0
                    ? firstRef.refs[0].repRef
                    : undefined;
            if (foundRef)
                break;
        }
    }
    if (foundRef)
        cameraFocus(molstar, [foundRef]);
}
/*
  this could be generalized by providing the keys to be joined
  (hopefully without much effort)
*/
export function joinMeanAndStdColumns(tableArray) {
    const exRow = tableArray[0];
    const headers = Object.keys(exRow);
    const colsToJoin = [];
    headers.forEach((colName) => {
        if (colName.endsWith(" mean")) {
            const prefix = colName.slice(0, -5);
            const sdName = `${prefix} std`;
            if (headers.includes(sdName)) {
                colsToJoin.push(prefix);
            }
        }
    });
    if (colsToJoin.length === 0)
        return;
    tableArray.forEach((row) => {
        colsToJoin.forEach((prefix) => {
            const meanVal = row[`${prefix} mean`];
            const sdVal = row[`${prefix} std`];
            row[`${prefix} (std)`] = `${meanVal} (${sdVal})`;
            delete row[`${prefix} mean`];
            delete row[`${prefix} std`];
        });
    });
    // const headersPre = Object.keys(tablejson);
    // headersPre.forEach((colName) => {
    //   if (colName.endsWith(" mean")) {
    //     const prefix = colName.slice(0, -5);
    //     const sdName = `${prefix} std`;
    //     if (headersPre.includes(sdName)) {
    //       const rowIDs = Object.keys(tablejson[colName]);
    //       const mergedColDat: { [id: string]: string } = {};
    //       rowIDs.forEach((i: string) => {
    //         const meanVal = tablejson[colName][i];
    //         const sdVal = tablejson[sdName][i];
    //         mergedColDat[i] = `${meanVal} (${sdVal})`;
    //       });
    //       tablejson[`${prefix} (std)`] = mergedColDat;
    //       delete tablejson[colName];
    //       delete tablejson[sdName];
    //     }
    //   }
    // });
}
const prettifiedTableLabels = {
    pkd: "pK<sub>d</sub>",
    pki: "pKI",
    kd: "K<sub>d</sub>",
    ki: "KI",
    ic50: "IC50",
    pic50: "pIC50",
    dg: "ΔG",
};
function replacer(match, p1, p2, p3, offset, string) {
    return p1 + prettifiedTableLabels[p2.toLowerCase()] + p3;
}
// (match start or space)  (any of the keywords)  (match end or space)
//            Group1          Group2             Group3
//             |----||---------------------------||----| |```` case insensitive search
const regex = /(^|\s)(pkd|pki|kd|ki|ic50|pic50|dg)($|\s)/i;
export function prettifyLabel(label) {
    return label.replace(regex, replacer).replace(/_/g, " ");
}
function roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
}
export function showInExponentialNotation(arg, upperBound = 99999, lowerBound = 0.0001) {
    if (typeof arg !== "number")
        return arg;
    if (Number.isInteger(arg))
        return arg;
    if (Math.abs(arg) < lowerBound || Math.abs(arg) > upperBound) {
        // || decimalCount(arg) > 5
        return arg.toExponential(4);
    }
    else
        return roundNumber(arg, 4);
}
export function decimalCount(value) {
    if (Math.floor(value) === value)
        return 0;
    return value.toString().split(".")[1].length || 0;
}
export function calculateTableHeight(tables, plots) {
    const numTables = tables.filter((t) => !t.archived).length;
    const numPlots = plots.filter((p) => !p.archived).length;
    const tableSel = numTables > 1;
    const tabs = numPlots > 0;
    return `calc(100vh - 60px${tableSel ? " - 56px" : ""}${tabs ? " - 56px" : ""}${tableSel || tabs ? " - 34px" : ""})`;
}
export function findLoadColumnWithRepeatedFile(tableArray, loadOnRowClick) {
    // We need to check if the file to load on row click is the same in more than one row. If this is the case, on row click we will check if this file is already present on another active row.
    // We check this on table load to avoid checking on each row click in the cases where there is no repetition in the files to load at different rows.
    // If only one file loads per row, we don't need to check.
    if (tableArray.length <= 0)
        return;
    const testRow = tableArray[0];
    const maxCheck = 10000;
    const checkColumns = [];
    for (const loadAction of loadOnRowClick) {
        if (loadAction.action !== TableClickAction.loadFile)
            continue;
        if (loadAction.column.length <= 1)
            continue;
        loadAction.column.forEach((actionColumn) => {
            const loadRowCol = Object.keys(testRow).find((rowCol) => rowCol.toLowerCase() === actionColumn.toLowerCase());
            if (loadRowCol) {
                checkColumns.push(loadRowCol);
            }
        });
    }
    if (checkColumns.length === 0)
        return;
    const allColsData = {};
    let n = 0;
    for (const row of tableArray) {
        for (const colName of checkColumns) {
            if (!(colName in allColsData)) {
                allColsData[colName] = new Set();
            }
            allColsData[colName].add(row[colName]);
        }
        if (n >= maxCheck)
            break; // Stop is the table is too big
    }
    const repeatedFileCol = [];
    const lenTable = tableArray.length;
    const _lenTable = lenTable > maxCheck ? maxCheck : lenTable;
    for (const colName of checkColumns) {
        const lenColSet = allColsData[colName].size;
        if (lenColSet < _lenTable) {
            repeatedFileCol.push(colName.toLowerCase());
        }
    }
    return repeatedFileCol;
}
