import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Select, MenuItem } from "@mui/material";
import { DataVizTabs } from "../DataViz/DataVizTabs";
import { trimText } from "..";
function SelectTableSelector({ handleChange, activeTableIdx, tables, }) {
    const onChange = (event) => {
        handleChange(Number(event.target.value));
    };
    return (_jsx(Select, Object.assign({ labelId: "select-table-label", id: "select-table", value: activeTableIdx.toString(), label: "", onChange: onChange }, { children: tables.map((t, i) => (_jsx(MenuItem, Object.assign({ value: i, sx: { display: t.archived ? "none" : undefined } }, { children: trimText(t.name, 50, true) }), `opt${i}`))) })));
}
function SelectTableTabs({ handleChange, activeTableIdx, tables, }) {
    const tableTabs = tables.map((t) => {
        var _a, _b;
        const tableTab = { name: t.name };
        if ((_a = t.options) === null || _a === void 0 ? void 0 : _a.color)
            tableTab["color"] = (_b = t.options) === null || _b === void 0 ? void 0 : _b.color;
        return tableTab;
    });
    const callback = (val) => {
        handleChange(tableTabs.findIndex((t) => t.name === val));
    };
    return (_jsx(DataVizTabs, { tabs: tableTabs, activeTab: tables[activeTableIdx].name, callback: callback }));
}
export function SelectTable({ handleChange, activeTableIdx, tables, fileSelectorAsTabs, }) {
    return fileSelectorAsTabs ? (_jsx(SelectTableTabs, { handleChange: handleChange, activeTableIdx: activeTableIdx, tables: tables })) : (_jsx(SelectTableSelector, { handleChange: handleChange, activeTableIdx: activeTableIdx, tables: tables }));
}
