import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ControlledSlider } from "../Controls/Slider/ControlledSlider";
import { Box, Typography, Grid } from "@mui/material";
export function OpacitySlider({ currVal, disabled = false, onUpdate, }) {
    return (_jsx(Box, Object.assign({ sx: { padding: "5px 11px" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", sx: { pr: 1 } }, { children: "Opacity" })) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: { minWidth: "5em" } }, { children: _jsx(ControlledSlider, { currVal: currVal, disabled: disabled, onUpdate: onUpdate, minVal: 0, maxVal: 1, step: 0.1 }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", sx: { pr: 1 } }, { children: currVal })) }))] })) })));
}
