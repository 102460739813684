import { dispatchNotificationEvent } from "../NotificationSystem/utils";
export const addQueryParam = (urlString, param, newVal) => {
    try {
        const url = new URL(urlString);
        const params = url.searchParams;
        if (params.has(param)) {
            params.set("prompt", newVal);
        }
        else {
            params.append(param, newVal);
        }
        url.search = params.toString();
        return url.toString();
    }
    catch (_a) {
        return;
    }
};
export const handleGoogleAuthError = (error) => {
    if (error)
        console.log(error);
    dispatchNotificationEvent({
        message: error
            ? error
            : "Google authentication error. Please try again or contact support if the issue continues.",
        type: "error",
    });
};
