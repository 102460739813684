// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
/**
 * Dispatch a custom event of type "PM-Confirmation-Dialog". The client is
 * responsible for having an appropriate listener and callback in place.
 * @param {string} message - What to show in the dialog.
 * @returns A promise whose resolve() function can be used for confirmation.
 */
function dispatchConfirmationDialogEvent({ message, }) {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent("PM-Confirmation-Dialog", {
            detail: { message, resolve },
        }));
    });
}
export default dispatchConfirmationDialogEvent;
