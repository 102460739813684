import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Tooltip } from "@mui/material";
const ButtonBox = ({ tooltipMsg, tooltipPlacement, disabled, active, backgroundColor, iconColor, backgroundHoverColor, iconHoverColor, onMouseOver, onMouseOut, children, }) => {
    const _backgroundColor = backgroundColor ? backgroundColor : "auto";
    const _backgroundHoverColor = backgroundHoverColor
        ? backgroundHoverColor
        : "primary.main";
    const _iconColor = iconColor ? iconColor : "auto";
    const _iconHoverColor = iconHoverColor ? iconHoverColor : "secondary.main";
    const box = (_jsx(Box, Object.assign({ sx: {
            height: "100%",
            width: "min-content",
            cursor: "pointer",
            borderRadius: 1,
            transition: "background 0.5s, color 0.5s",
            backgroundColor: active ? _backgroundHoverColor : _backgroundColor,
            "& .MuiSvgIcon-root": { color: active ? _iconHoverColor : _iconColor },
            "&:hover": {
                backgroundColor: disabled ? _backgroundColor : _backgroundHoverColor,
                "& .MuiSvgIcon-root": {
                    color: disabled ? _iconColor : _iconHoverColor,
                },
            },
        } }, { children: children })));
    return (_jsx(_Fragment, { children: tooltipMsg ? (_jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, placement: tooltipPlacement ? tooltipPlacement : "top-end", title: tooltipMsg }, { children: box }))) : (_jsx(_Fragment, { children: box })) }));
};
export default ButtonBox;
