import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { StateObjectRef } from "molstar/lib/mol-state";
import { indexSelector, addReverseIndexing } from "..";
export function toggleVisibility(molstar, cellRefs) {
    cellRefs.forEach((cellRef) => {
        const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
        if (selectedStruct) {
            PluginCommands.State.ToggleVisibility(molstar, {
                state: selectedStruct.parent,
                ref: selectedStruct.transform.parent,
            });
        }
    });
}
export function setVisibility(molstar, cellRefs, visibility) {
    cellRefs.forEach((cellRef) => {
        const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
        if (selectedStruct) {
            const stateIsVisible = selectedStruct.state.isHidden === undefined
                ? true
                : !selectedStruct.state.isHidden;
            if (stateIsVisible !== visibility) {
                PluginCommands.State.ToggleVisibility(molstar, {
                    state: selectedStruct.parent,
                    ref: selectedStruct.transform.parent,
                });
            }
        }
    });
}
export function setVisibilityForSDF(molstar, activeTextFieldVal, refs) {
    // Toggle the visibility of SDF frames
    if (!activeTextFieldVal)
        return;
    const repRefs = refs.map((x) => {
        return x.repRef;
    });
    let newActiveElements = [...repRefs];
    if (activeTextFieldVal) {
        try {
            newActiveElements = indexSelector(activeTextFieldVal, addReverseIndexing(repRefs));
        }
        catch (_a) { }
    }
    setVisibility(molstar, repRefs, false); //set all to visibility false
    setVisibility(molstar, newActiveElements, true); //set selected refs to visibility true
}
